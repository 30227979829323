import React from 'react';
import Tabs from 'devextreme-react/tabs';

import './InlineInspection.css';
import DeliverableTab from '../Tabs/DeliverableTab.js';
import DueDateExtensionTab from '../Tabs/DueDateExtensionTab.js';
import VendorRequestTab from '../Tabs/VendorRequestTab.js';

const tabs = [
  {
    id: 0,
    text: 'Project Deliverables',
    icon: 'parentfolder'
  },
  {
    id: 1,
    text: 'Deliverable Extension Requests',
    icon: 'file'
  },
  {
    id: 2,
    text: 'Vendor Requests',
    icon: 'file'
  }
];

export default class InlineInspection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0
    };
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
  }

  onTabsSelectionChanged(args) {
    if(args.name === 'selectedIndex') {
      this.setState({
        selectedIndex: args.value
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Tabs
          dataSource={tabs}
          selectedIndex={this.state.selectedIndex}
          onOptionChanged={this.onTabsSelectionChanged}
        />
        <div className="content">
          {this.state.selectedIndex === 0 && <DeliverableTab />}
          {this.state.selectedIndex === 1 && <DueDateExtensionTab />}
          {this.state.selectedIndex === 2 && <VendorRequestTab />}
        </div>
      </React.Fragment>
    );
  }
}

