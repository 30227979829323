import { companies } from '../Data/companies.js';

export const getEnvironmentVariable = () => {
  let env = process.env.REACT_APP_CCS_ENV;
  if (env === 'PROD') {
    return 'PRD_USR';
  } else if (env === 'QA') {
    return 'QUA_USR';
  } else if (env === 'TEST') {
    return 'TST_USR';
  } else if (env === 'DEV') {
    return 'DEV_USR';
  }

  return 'NA';
}

export const validateAuthGroup = () => {
  let authInfo = sessionStorage.getItem('authInfo');
  if (!authInfo) {
    return false;
  }

  let authInfoJson = JSON.parse(authInfo);
  let admin = `R_COGN_PIVendorPortal_Admin-${getEnvironmentVariable()}`;
  let projMgmt = `R_COGN_PIVendorPortal_ProjMgmt-${getEnvironmentVariable()}`;
  let threatMgmt = `R_COGN_PIVendorPortal_ThreatMgmt-${getEnvironmentVariable()}`;
  let readOnly = `R_COGN_PIVendorPortal_ReadOnly-${getEnvironmentVariable()}`;
  let vendor = `G_EXT_PIVendorPortal_ReadWrite-${getEnvironmentVariable()}`;

  let internal = authInfoJson?.claims?.["custom:groups"]?.includes(admin) 
    || authInfoJson?.claims?.["custom:groups"]?.includes(projMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(threatMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(readOnly);

  let external = authInfoJson?.claims?.["custom:groups"]?.includes(vendor); 
  
  if (internal) {
    return true;
  } else if (external) {
    let company = authInfoJson?.claims?.["custom:company"];
    let found = companies.find(item => item.Id.toUpperCase() === company.toUpperCase());
    if (found) {
      return true;
    }
  }
    
  return false;
}

export const isReadOnly = () => {
  let authInfo = sessionStorage.getItem('authInfo');
  if (!authInfo) {
    return true;
  }

  let authInfoJson = JSON.parse(authInfo);
  let admin = `R_COGN_PIVendorPortal_Admin-${getEnvironmentVariable()}`;
  let projMgmt = `R_COGN_PIVendorPortal_ProjMgmt-${getEnvironmentVariable()}`;
  let threatMgmt = `R_COGN_PIVendorPortal_ThreatMgmt-${getEnvironmentVariable()}`;
  let vendor = `G_EXT_PIVendorPortal_ReadWrite-${getEnvironmentVariable()}`;

  let found = authInfoJson?.claims?.["custom:groups"]?.includes(admin) 
    || authInfoJson?.claims?.["custom:groups"]?.includes(projMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(threatMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(vendor);

  if (found) {
    return false;
  } else {
    return true;
  }
}

export const isAdmin = () => {
  let authInfo = sessionStorage.getItem('authInfo');
  if (!authInfo) {
    return false;
  }

  let authInfoJson = JSON.parse(authInfo);
  let admin = `R_COGN_PIVendorPortal_Admin-${getEnvironmentVariable()}`;
  let found  = authInfoJson?.claims?.["custom:groups"]?.includes(admin); 
 
  return found;
}

export const isTCUser = () => {
  let authInfo = sessionStorage.getItem('authInfo');
  if (!authInfo) {
    return false;
  }

  let authInfoJson = JSON.parse(authInfo);
  let admin = `R_COGN_PIVendorPortal_Admin-${getEnvironmentVariable()}`;
  let projMgmt = `R_COGN_PIVendorPortal_ProjMgmt-${getEnvironmentVariable()}`;
  let threatMgmt = `R_COGN_PIVendorPortal_ThreatMgmt-${getEnvironmentVariable()}`;
  let readOnly = `R_COGN_PIVendorPortal_ReadOnly-${getEnvironmentVariable()}`;

  let found = authInfoJson?.claims?.["custom:groups"]?.includes(admin) 
    || authInfoJson?.claims?.["custom:groups"]?.includes(projMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(threatMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(readOnly);

  return found;
}

export const getLoginUser = () => {
  let authInfo = sessionStorage.getItem('authInfo');
  let authInfoJson = JSON.parse(authInfo);
  let user = authInfoJson?.claims?.["name"]

  if (user) {
    return user;
  } else {
    return 'N/A'
  }
}

export const getLoginVendor = () => {
  let authInfo = sessionStorage.getItem('authInfo');
  let authInfoJson = JSON.parse(authInfo);
  let admin = `R_COGN_PIVendorPortal_Admin-${getEnvironmentVariable()}`;
  let projMgmt = `R_COGN_PIVendorPortal_ProjMgmt-${getEnvironmentVariable()}`;
  let threatMgmt = `R_COGN_PIVendorPortal_ThreatMgmt-${getEnvironmentVariable()}`;
  let readOnly = `R_COGN_PIVendorPortal_ReadOnly-${getEnvironmentVariable()}`;

  let internal = authInfoJson?.claims?.["custom:groups"]?.includes(admin) 
    || authInfoJson?.claims?.["custom:groups"]?.includes(projMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(threatMgmt)
    || authInfoJson?.claims?.["custom:groups"]?.includes(readOnly);

  if (internal) {
    return 'TC';
  } else {
    let company = authInfoJson?.claims?.["custom:company"];
    let found = companies.find(item => item.Id.toUpperCase() === company.toUpperCase());
    if (found) {
      return found.Id;
    }
  }
}

export const getLoginFolder = () => {
  let publisher = getLoginVendor();
  if ( publisher === 'TC') {
    return 'All'
  } else {
    return publisher;
  }
}

export const getRequestHeaders = () => {
  let authInfo = sessionStorage.getItem('authInfo');
  if (!authInfo) {
    return {};
  }

  let authInfoJson = JSON.parse(authInfo);
  let token = authInfoJson.token;
  let ccsSessionKey = authInfoJson.ccsSessionKey;
  
  return {
      "Authorization": token,
      "ccsSessionKey": ccsSessionKey,
      'Access-Control-Allow-Origin': '*',
      "Content-Type":"Application/json"
  }
}

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
}

export const updateAuthInfo = (token, ccsSessionKey) => {
  let authInfo = {
      token: token,
      claims: parseJwt(token),
      ccsSessionKey: JSON.parse(sessionStorage.getItem('authInfo')).ccsSessionKey
  };
  sessionStorage.setItem('authInfo', JSON.stringify(authInfo));
}

// This method should only be used when running application locally for development purpose.
// To run application locally, set it to 'true'
export const runLocalhost = () => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );
  return isLocalhost;  
}

// This method should only be used when running application locally for development purpose.
// Replace token value with a real token received from the server to pass authentication
export const setupLocalhost = () => {
  let token = ''
  let authInfo = {
    ccsSessionKey: '',
    token: token,
    claims: parseJwt(token)
  };
  sessionStorage.setItem('authInfo', JSON.stringify(authInfo));
}

// This method should only be used when running application locally for development purpose.
export const getAuthInfo = (action) => {
  return {};
}