import React from 'react'
import axios from "axios";
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import Popup from 'devextreme-react/popup';
import Validator, { RequiredRule } from "devextreme-react/validator";
import ValidationGroup from 'devextreme-react/validation-group';
import { alert } from 'devextreme/ui/dialog';
import './AddCommonFile.css'
import { getRequestHeaders, getLoginVendor } from '../Utility/Utils.js'

const FILE_CHUNK_SIZE = 200*1024*1024 // 200 MB

function escapeSpecialCharacters(value) {
  return value.replaceAll("'", "''");
}

export default class AddCommonFile extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      publisher: getLoginVendor(),
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      totalPackage: 0,
      completedPackage: 0,
      fileAddPopupVisible: false, 
      file: null,
      allowedFileExtensions: [],
      displayTitle: ''
    };
  }

  handleAddFilePopupHidden = () => {
    this.setState({
      file: null,
      displayTitle: ''
    });

    // call parent componenet to hide the popup
    this.props.hidePopup();
  }

  /**
  * Set visibility of loading panel
  */
  toggleLoadingPanel = (status, message='Loading...') => {
    this.setState({
      loadPanelVisible: status,
      loadMessage: message
    });
  }

  completed = () => {
    this.props.refresh();
    this.props.hidePopup();
  }

  handleFileChanged = (e) => {
    // console.log("File event: ", e);
    if (e.value && e.value[0]) {
      this.setState({
        file: e.value[0],
        // keep file name without extension
        displayTitle: e.value[0].name.split('.').slice(0, -1).join('.')
      });
    } else {
      this.setState({
        file: null
      });
    }
  }

  handleFileUpload = (e) => {
    let result = this.validationGroup.instance.validate();
    if (!result.isValid)  {
      console.log("handleFileUpload isValied: ", result.isValid);
      return false;
    }

    // API Gateway url pointing to upload lambda
    let upload_url = `${process.env.REACT_APP_API_GATEWAY}/uploadFile`;
    // API Gateway url pointing to complete upload lambda
    let complete_upload_url =  `${process.env.REACT_APP_API_GATEWAY}/completeUpload`;

    // remove non ascii characters
    let fileName = this.state.file.name.replace(/[^\x00-\x7F]/g, "");
    let fileSize = this.state.file.size;
    let part_size = Math.ceil(fileSize / FILE_CHUNK_SIZE)
    console.log("Total file size: ", fileSize)

    // authentication info
    let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));

    // reset progress counter
    this.setState({
      totalPackage: part_size,
      completedPackage: 0
    });

    // metadata tags for file
    let body = {
      "file_name":fileName, 
      "part_size": part_size, 
      "size": fileSize,
      "display_title": this.state.displayTitle,
      "folder": "Common",
      "created_by": authInfo.claims.name
    }
    console.log("uploadFile body: ", body);

    this.toggleLoadingPanel(true, `Uploading file...0%`);
      // API Gatway request
      axios.post(upload_url, body, {headers: getRequestHeaders()})
        .then(response => {
          // error in request
          if (response.data.error) {
            console.log(`Error happened: ${response.data.error}`);
            this.toggleLoadingPanel(false);
            alert({message:`<b style="color:rgb(217,83,79)";>An error occured while uploading the file.</b></br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>${response.data.error}</i>`, showTitle:false});
            return;
          }

          this.setState({
            loadMessage: `Uploading file...1%`
          });

          //successfull getting signed url. Add the item to the states with uploading status
          var t0 = performance.now();
          let urls = response.data.upload_urls;
          let upload_id = response.data.upload_id;
          console.log("urls: ", urls);

          delete axios.defaults.headers.put['Content-Type']
          const promises = []

          urls.forEach((url, part) =>{
            const currentPackage = part;
            const start = part * (FILE_CHUNK_SIZE)
            const end = (part + 1) * (FILE_CHUNK_SIZE)
            const blob = part <= part_size - 1? this.state.file.slice(start, end) : this.state.file.slice(start)

            const options = {
              method: "PUT",
                url: url,
                data: blob,
                headers: { "Content-Type": "multipart/form-data"}
            }

            promises.push(
              new Promise(async (resolve, reject) => {
                let retries = 0;
                const maxRetries = 3;
              
                while (retries < maxRetries) {
                  try {
                    const response = await axios(options);
                    this.setState({
                      completedPackage: this.state.completedPackage + 1,
                      loadMessage: `Uploading file...${ Math.round((this.state.completedPackage*100/this.state.totalPackage)*98)/100 + 1 }%`
                    });
                    console.log("currentPackage: ", currentPackage);
                    resolve(response);
                    break;
                  } catch (err) {
                    retries++;
                    if (retries === maxRetries) {
                      reject(err);
                    } else {
                      const status = err?.response?.status || 500;
                      console.log(`Error Status: ${status}`);
                      console.log(`Retry pacakge: ${currentPackage}`);
                    }
                  }
                }
              })   
            )
          })

          Promise.all(promises)
            .then((result) => {
              let etags = result.map((r, index)=> JSON.parse(`{"ETag": ${r.headers.etag}, "PartNumber": ${index+1}}`))
              body["parts"] = etags
              body["upload_id"] = upload_id

              axios.post(complete_upload_url, body, {headers : getRequestHeaders()})
                .then((result) => {
                  var t1 = performance.now()
                  console.log("Time taken to upload file: ", (t1 - t0), " milliseconds.")

                  this.setState({
                    loadMessage: `Uploading file...100%`
                  });
                  
                  this.toggleLoadingPanel(false);
                  this.completed();
                  alert({message:'<b style="color:rgb(92,184,92)";>The file uploaded successfully.</b>', showTitle:false});
                }).catch(error => {
                  console.log(`Error happened in completing multipart uploading: ${error}`);
                  this.toggleLoadingPanel(false);
                  alert({message:`<b style="color:rgb(217,83,79)";>An error occured while uploading the file.</b></br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>Error happened in completing multipart uploading.</br>${error}</i>`, showTitle:false});
                })
            }).catch(error => {
              console.log(`Error happened in multipart uploading: ${error}`);
              this.toggleLoadingPanel(false);
              alert({message:`<b style="color:rgb(217,83,79)";>An error occured while uploading the file.</b></br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>Error happened in multipart uploading.</br>${error}</i>`, showTitle:false});
            })
          }).catch(error => {
            console.log("Error happened in getting signed URL:", error);
            this.toggleLoadingPanel(false);
            alert({message:`<b style="color:rgb(217,83,79)";>An error occured while uploading the file.</b></br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>Error happened in getting signed URL.</br>${error}</i>`, showTitle:false});
          })
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name="loadPanel"
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true} />
        <Popup
          width={600} 
          height={500}
          showTitle={true}
          title='Add Reference Guide'
          dragEnabled={false}
          hideOnOutsideClick={false}
          visible={this.props.popupVisible}
          onHiding={this.handleAddFilePopupHidden}>
          <ValidationGroup ref={ref => this.validationGroup = ref}>
            <div id="popup" className="cf-popup-details">
              <div className="dx-fieldset">
                <div className="dx-field">
                  <div className="cf-popup-option">
                    <span>Display Title:</span>
                    <TextBox width={520} 
                      value={this.state.displayTitle}
                      onValueChanged={(e) => this.setState({displayTitle: e.value})} >
                      <Validator>
                        <RequiredRule />
                      </Validator>
                    </TextBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="cf-popup-option">
                    <span>File:</span>
                    <div className="cf-popup-fileuploader-container">
                      <FileUploader
                        selectButtonText="Select File" 
                        labelText="" 
                        accept="*" 
                        uploadMode="useForm" 
                        onValueChanged={(e) => this.handleFileChanged(e)} >
                        <Validator>
                          <RequiredRule />
                        </Validator>
                      </FileUploader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ValidationGroup>
          <div className="cf-popup-buttons">
            <Button
              icon="upload"
              text="Upload"
              width={210}
              height={44}
              elementAttr={{class: 'favorites'}}
              onClick={this.handleFileUpload} />
          </div>
        </Popup>
      </React.Fragment>
    );
  }
}