import Axios from "axios";
import { updateAuthInfo } from '../Utility/Utils.js'

export const RefreshToken = () => {
  console.log("Start token refresh interval");
  var timer = setInterval(function() {
    let authInfo = sessionStorage.getItem('authInfo');
    if (!authInfo) {
      console.log("No logged-in user");
      return;
    }

    let authInfoJson = JSON.parse(sessionStorage.getItem('authInfo'));
    let token = authInfoJson.token;
    let ccsSessionKey = authInfoJson.ccsSessionKey;
    
    if (!ccsSessionKey) {
      console.log("No session key.");
      return;
    }

    let url = `${process.env.REACT_APP_API_GATEWAY}/refreshToken`;
    console.log("url: ", url);

    let header ={
      "Authorization": token,
      "ccsSessionKey": ccsSessionKey,
      "Content-Type":"Application/json"
    }

    Axios.get(url, {headers: header})
      .then(response => {
        console.log(response);
        updateAuthInfo(response.data.id_token, ccsSessionKey);
      }).catch(err => {
        console.log(err);
        console.log("Stop interval");
        // stop interval
        clearInterval(timer);
      })

  }, 3000000) // every 50 minutes
}
