import React from 'react';
import axios from "axios";
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Button, Column, Editing, Grouping, GroupPanel, SearchPanel, FilterPanel, FilterRow, FilterBuilder, Paging, Pager} from 'devextreme-react/data-grid';
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alert } from 'devextreme/ui/dialog';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { isAdmin, getRequestHeaders, getLoginFolder } from '../Utility/Utils.js'
import './DeliverableView.css';

const groupOperations = ['and', 'or'];

const customOperations = [
  {
    name: "isblank",
    caption: "Is Null",
    dataTypes: ["string", "date"],
    hasValue: false,
    icon: "isblank"
  },
  {
    name: "isnotblank",
    caption: "Is Not Null",
    dataTypes: ["string", "date"],
    hasValue: false,
    icon: "isnotblank"
  },
  {
    name: "=",
    caption: "Equals",
    dataTypes: ["string", "date", "int"],
    hasValue: true,
    icon: "equal"
  },
  {
    name: "<>",
    caption: "Does not equal",
    dataTypes: ["string", "date", "int"],
    hasValue: true,
    icon: "notequal"
  },
  {
    name: "contains",
    caption: "Contains",
    dataTypes: ["string"],
    hasValue: true,
    icon: "contains"
  },
  {
    name: "notcontains",
    caption: "Does not Contain",
    dataTypes: ["string"],
    hasValue: true,
    icon: "contains"
  },
  {
    name: "startswith",
    caption: "Starts with",
    dataTypes: ["string"],
    hasValue: true,
    icon: "startswith"
  },
  {
    name: "endswith",
    caption: "Ends with",
    dataTypes: ["string"],
    hasValue: true,
    icon: "endswith"
  },
  {
    name: "between",
    caption: "Between",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "between"
  },
  {
    name: "<",
    caption: "Less than",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "less"
  },
  {
    name: ">",
    caption: "Greater than",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "greater"
  },
  {
    name: "<=",
    caption: "Less than or equal to",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "lessorequal"
  },
  {
    name: ">=",
    caption: "Greater than or equal to",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "greaterorequal"
  }
];

const fields = [
  {
    caption: 'File Name',
    dataField: 'FileName',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'MPID',
    dataField: 'MPID',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Country',
    dataField: 'Country',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'MP Vendor',
    dataField: 'Vendor',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Asset/Lob',
    dataField: 'AssetOrLob',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Assessment Path',
    dataField: 'AssessmentPath',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Subtype',
    dataField: 'Subtype',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Deliverable Type',
    dataField: 'DeliverableTypeDis',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Deliverable Subtype',
    dataField: 'DeliverableSubtypeDis',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Created By',
    dataField: 'CreatedBy',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: receivedDate_header(),
    dataField: 'ReceivedDate',
    dataType: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    filterOperations: ['isblank', 'isnotblank', 'between', '=', '<>', '>', '<', '>=', '<=']
  }
];

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

function convertDatetime(filters) {
  if (Array.isArray(filters)) {
    if (filters[0] === 'ReceivedDate' && filters.length === 3) {
      if (Date.parse(filters[2])) {
        filters[2] = (new Date(filters[2])).toISOString()
      }
    } else {
      filters.forEach(function(item) {
        console.log('item:', item);
        convertDatetime(item)
      })
    }
  }
}

function receivedDate_header() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Received Date (' + timezone + ')';
}

function fileName_renderCell(cellData) {
  return <a href="/#" onClick={ () => download(cellData.data.FileKey) }>{ cellData.data.FileName }</a>;
}

function download(key) {
  let url = `${process.env.REACT_APP_API_GATEWAY}/downloadFile`;
  let body = {
    "key": key
  }

  axios.post(url, body, {headers: getRequestHeaders()})
    .then(response => {
      console.log(response);
      var iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = response.data;
      document.body.appendChild(iframe);
    }).catch(err => {
      console.log(err);
      alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Failed to download file, try again or contact support.</b>', showTitle:false});
    })
}

export default class FileView extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...'
    };

    this.preLoadingParams = '';
    this.preResults = {};

    this.fileDS = new CustomStore({
      key: 'FileKey',
      load: (loadOptions) => {
        console.log('loadOptions:', loadOptions);

        let filters = loadOptions['filter'];
        if (isNotEmpty(filters)) {
          // convert datetime to UTC string
          convertDatetime(filters);
        }

        let currentFolder = this.props.currentFolder || getLoginFolder();
        let params = `key=${currentFolder}&`;

        [
          'skip',
          'take',
          'sort',
          'filter'
        ].forEach((i) => {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        // remove ending &
        params = params.slice(0, -1);
        // escape special characters
        params = params.replace('{','%7B');
        params = params.replace('}','%7D');

        // triggered by exporting data
        if(isNotEmpty(loadOptions['isLoadingAll'])) {
          let pageSize = 5000;
          let total = Math.ceil(this.preResults.totalCount / pageSize);
          let promises = [];
          let responses= {};

          for (let pageIndex = 0; pageIndex < total; pageIndex++)  {
            let url = `${process.env.REACT_APP_API_GATEWAY}/getFiles?${params}`;
            url += `&skip=${pageIndex * pageSize}`;
            url += `&take=${pageSize}`;
            console.log("url: ", url);

            promises.push(
              new Promise(async (resolve, reject) => {
                axios.get(url, {headers: getRequestHeaders()})
                  .then((response) => {
                    console.log("response: ", response);
                    responses[pageIndex] = response;
                    resolve();
                  }).catch(err => {
                    console.log("error: ", err);
                    alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false})
                    reject(err);
                  });
              })
            )
          }

          return Promise.all(promises)
            .then(() => {
              let results = {data:[], totalCount:0};
              // combine all results in order
              for (let pageIndex = 0; pageIndex < total; pageIndex++) {
                results.data = results.data.concat(responses[pageIndex].data.data);
                results.totalCount = responses[pageIndex].data.totalCount;
              }
              console.log("combined results: ", results);
              return results;
            })
        } else {
          // avoid unnecessary reloading
          if(params === this.preLoadingParams) {
            console.log("skip loading");
            return new Promise((resolve) => {resolve(this.preResults)});
          } else {
            console.log("reset preLoadingParams");
            this.preLoadingParams = params;
          }

          let url = `${process.env.REACT_APP_API_GATEWAY}/getFiles?${params}`;
          console.log("url: ", url);

          return axios.get(url, {headers: getRequestHeaders()})
            .then((response) => {
              console.log("response: ", response);
              let results = {data: response.data.data, totalCount: response.data.totalCount};
              this.preResults = results;
              return results;
            }).catch(err => {
              console.log("error: ", err);
              alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false})
              let results = {data: [], totalCount: 0};
              this.preResults = results;
              return results;
            });
        }
      },
    });

    this.DataGridRef = React.createRef();
  }

  /**
    * Refresh the file view grid
    */
  refresh = (currentFolder) => {
    this.preLoadingParams = '';
    this.toggleLoadingPanel(true);
    this.DataGridRef.current.instance.refresh();
    this.toggleLoadingPanel(false);
  }

  /**
    * Remove a file from S3 and subsequently the file view 
    */
  delete = (e) => {
    if (isAdmin()) {
      let result = confirm("Are you sure you want to delete this file?", "Delete File");
      result.then((dialogResult) => {
        if (dialogResult) {
          let key = e.row.data.FileKey;
          let delete_url = `${process.env.REACT_APP_API_GATEWAY}/deleteFile`;
          let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));
          let userEmail = authInfo.claims.email;

          let body = {
            "key": key,
            "user": userEmail
          }
      
          this.toggleLoadingPanel(true, 'Processing...');
          axios.post(delete_url, body, {headers: getRequestHeaders()})
            .then(response => {
              console.log(response);
              this.toggleLoadingPanel(false);
              this.refresh(this.props.currentFolder);
            }).catch(err => {
              console.log(err);
              this.toggleLoadingPanel(false);
              alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Failed to delete file, try again or contact support.</b>', showTitle:false});
            })
        }
      });
    }
    
    e.event.preventDefault();
  }

  enableDeleteButton = (e) => {
    if (e.row.data.Status === 'Tally') {
      return false;
    }
    return true;
  }

  /**
    * Reset the file view grid layout
    */
   resetLayout = () => {
    this.preLoadingParams = '';
    this.DataGridRef.current.instance.state({});
    this.DataGridRef.current.instance.clearFilter();
  }

  /**
    * When multple rows are selected in the file view grid and the download button is clicked
    * Downlad all selected files
    */
  downloadFiles = () => {
    // get selected rows in file view grid
    let files = this.DataGridRef.current.instance.getSelectedRowsData();
    if (files.length > 0) {
      files.forEach((file) => {
        download(file.FileKey);
      })
    } else {
      alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Must have at least one file selected for download.</b>', showTitle:false});
    }
  }

  /**
    * Set visibility of loading panel
    */
  toggleLoadingPanel = (status, message='Loading...') => {
    this.setState({
      loadPanelVisible: status,
      loadMessage: message
    });
  }

  /**
    * Export file rows from the grid view to excel
    */
  onExporting = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: this.DataGridRef.current.instance,
      worksheet: worksheet,
      customizeCell: function(options) {
        const excelCell = options;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      } 
    }).then(function() {
      workbook.xlsx.writeBuffer()
        .then(function(buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProjectDeliverables.xlsx');
        });
    });
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name='loadPanel'
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true}
        />
        <DataGrid
          id="fileGridContainer"
          ref={this.DataGridRef}
          dataSource={this.fileDS}
          remoteOperations={{groupPaging: false, paging: true, filtering: true, sorting: true, grouping: false, summary: false}}
          showBorders={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          headerFilter={{ visible: true }}
          allowColumnResizing={true}
          columnResizingMode='widget'
          columnMinWidth={60}
          height={() => window.innerHeight - 250}
          selection={{ mode: 'multiple', selectAllMode: 'page', showCheckBoxesMode: 'always' }}
          stateStoring={{ enabled: true, type: 'localStorage', storageKey: 'FileGridLayout' }}
          scrolling={{ mode: 'standard', showScrollbar: 'always' }}
        >
          <GroupPanel visible={false} />
          <SearchPanel width ={300} visible={true} />
          <Grouping autoExpandAll={false} />  
          <FilterPanel visible={true} />
          <FilterBuilder fields={fields} groupOperations={groupOperations} customOperations={customOperations} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true} />
          <Editing
            mode="row"
            allowUpdating={false}
            allowDeleting={this.enableDeleteButton}
            allowAdding={false}
            useIcons={true} />
          <Column dataField="FileName" caption='File Name' cellRender={fileName_renderCell} allowHeaderFiltering={false} minWidth={300} />
          <Column dataField="MPID" width={100} caption='MPID' allowHeaderFiltering={false} />
          <Column dataField="Vendor" width={120} caption='MP Vendor' allowHeaderFiltering={false} />
          <Column dataField="Country" width={70} caption='Country' allowHeaderFiltering={false}  />
          <Column dataField="AssetOrLob" width={80} caption='Asset/Lob' allowHeaderFiltering={false} />
          <Column dataField="AssessmentPath" width={220} caption='Assessment Path' allowHeaderFiltering={false} />
          <Column dataField="Subtype" width={140} caption='Subtype' />
          <Column dataField="DeliverableTypeDis" width={160} caption='Deliverable Type' />
          <Column dataField="DeliverableSubtypeDis" width={190} caption='Deliverable Subtype' />
          <Column dataField="CreatedBy" width={210} caption='Created By' allowHeaderFiltering={false} />
          <Column dataField="ReceivedDate" width={180} caption={receivedDate_header()} dataType="datetime" format='yyyy-MM-dd HH:mm:ss' defaultSortOrder="desc" />
          <Column dataField="Size" width={110} caption='Size (MB)' dataType="number" allowFiltering={false} allowHeaderFiltering={false} />
          <Column type="buttons" width={60} visible={isAdmin()} >
            <Button name="delete" onClick={this.delete} />
          </Column>
        </DataGrid>
      </React.Fragment>  
    );
  }
}

