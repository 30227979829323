import React from 'react';
import axios from "axios";
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, Editing, Grouping, GroupPanel, SearchPanel, FilterPanel, FilterRow, FilterBuilder, Paging, Pager, Selection} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alert } from 'devextreme/ui/dialog';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { getRequestHeaders, getLoginFolder, isTCUser } from '../Utility/Utils.js'
import './VendorRequestView.css';
import UpdateVendorRequest from '../Popups/UpdateVendorRequest.js';

const groupOperations = ['and', 'or'];

const customOperations = [
  {
    name: "isblank",
    caption: "Is Null",
    dataTypes: ["string", "date"],
    hasValue: false,
    icon: "isblank"
  },
  {
    name: "isnotblank",
    caption: "Is Not Null",
    dataTypes: ["string", "date"],
    hasValue: false,
    icon: "isnotblank"
  },
  {
    name: "=",
    caption: "Equals",
    dataTypes: ["string", "date", "int"],
    hasValue: true,
    icon: "equal"
  },
  {
    name: "<>",
    caption: "Does not equal",
    dataTypes: ["string", "date", "int"],
    hasValue: true,
    icon: "notequal"
  },
  {
    name: "contains",
    caption: "Contains",
    dataTypes: ["string"],
    hasValue: true,
    icon: "contains"
  },
  {
    name: "notcontains",
    caption: "Does not Contain",
    dataTypes: ["string"],
    hasValue: true,
    icon: "contains"
  },
  {
    name: "startswith",
    caption: "Starts with",
    dataTypes: ["string"],
    hasValue: true,
    icon: "startswith"
  },
  {
    name: "endswith",
    caption: "Ends with",
    dataTypes: ["string"],
    hasValue: true,
    icon: "endswith"
  },
  {
    name: "between",
    caption: "Between",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "between"
  },
  {
    name: "<",
    caption: "Less than",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "less"
  },
  {
    name: ">",
    caption: "Greater than",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "greater"
  },
  {
    name: "<=",
    caption: "Less than or equal to",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "lessorequal"
  },
  {
    name: ">=",
    caption: "Greater than or equal to",
    dataTypes: ["date", "int"],
    hasValue: true,
    icon: "greaterorequal"
  }
];

const fields = [
  {
    caption: 'Request ID',
    dataField: 'RequestID',
    dataType: 'int',
    filterOperations: ['=', '<>', '>', '<', '>=', '<=']
  },
  {
    caption: 'Status',
    dataField: 'Status',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Request Type',
    dataField: 'RequestType',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Vendor',
    dataField: 'Vendor',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'MPID',
    dataField: 'MPID',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Description',
    dataField: 'Description',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Requested By',
    dataField: 'CreatedBy',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: requestedDate_header(),
    dataField: 'CreatedDate',
    dataType: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    filterOperations: ['isblank', 'isnotblank', 'between', '=', '<>', '>', '<', '>=', '<=']
  },
  {
    caption: 'Assigned To',
    dataField: 'AssignedTo',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: 'Response',
    dataField: 'Response',
    dataType: 'string',
    filterOperations: ['isblank', 'isnotblank', '=', '<>', 'contains', 'notcontains', 'startswith', 'endswith']
  },
  {
    caption: closedDate_header(),
    dataField: 'ClosedDate',
    dataType: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    filterOperations: ['isblank', 'isnotblank', 'between', '=', '<>', '>', '<', '>=', '<=']
  }
];

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

function requestedDate_header() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Submitted Date (' + timezone + ')';
}

function closedDate_header() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Closure Date (' + timezone + ')';
}

function convertDatetime(filters) {
  if (Array.isArray(filters)) {
    if ((filters[0] === 'CreatedDate' || filters[0] === 'ClosedDate') && filters.length === 3) {
      if (Date.parse(filters[2])) {
        filters[2] = (new Date(filters[2])).toISOString()
      }
    } else {
      filters.forEach(function(item) {
        console.log('item:', item);
        convertDatetime(item)
      })
    }
  }
}

export default class VendorRequestView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      popupVisible: false,
      vendorRequest: null
    };

    this.preLoadingParams = '';
    this.preResults = {};

    this.requestDS = new CustomStore({
      key: 'RequestID',
      load: (loadOptions) => {
        console.log('loadOptions:', loadOptions);
        
        let filters = loadOptions['filter'];
        if (isNotEmpty(filters)) {
          // convert datetime to UTC string
          convertDatetime(filters);
        }

        let currentFolder = this.props.currentFolder || getLoginFolder();
        let params = `folder=${currentFolder}&`;

        [
          'skip',
          'take',
          'sort',
          'filter'
        ].forEach((i) => {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        // remove ending &
        params = params.slice(0, -1);
        console.log("params: ", params)
        // escape special characters
        params = params.replace('{','%7B');
        params = params.replace('}','%7D');

        // triggered by exporting data
        if(isNotEmpty(loadOptions['isLoadingAll'])) {
          let pageSize = 5000;
          let total = Math.ceil(this.preResults.totalCount / pageSize);
          let promises = [];
          let responses= {};

          for (let pageIndex = 0; pageIndex < total; pageIndex++)  {
            let url = `${process.env.REACT_APP_API_GATEWAY}/getVendorRequests?${params}`;
            url += `&skip=${pageIndex * pageSize}`;
            url += `&take=${pageSize}`;
            console.log("url: ", url);

            promises.push(
              new Promise(async (resolve, reject) => {
                axios.get(url, {headers: getRequestHeaders()})
                  .then((response) => {
                    console.log("response: ", response);
                    responses[pageIndex] = response;
                    resolve();
                  }).catch(err => {
                    console.log("error: ", err);
                    alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false})
                    reject(err);
                  });
              })
            )
          }

          return Promise.all(promises)
            .then(() => {
              let results = {data:[], totalCount:0};
              // combine all results in order
              for (let pageIndex = 0; pageIndex < total; pageIndex++) {
                results.data = results.data.concat(responses[pageIndex].data.data);
                results.totalCount = responses[pageIndex].data.totalCount;
              }
              console.log("combined results: ", results);
              return results;
            })
        } else {
          // avoid unnecessary reloading
          if(params === this.preLoadingParams) {
            console.log("skip loading");
            return new Promise((resolve) => {resolve(this.preResults)});
          } else {
            console.log("reset preLoadingParams");
            this.preLoadingParams = params;
          }

          let url = `${process.env.REACT_APP_API_GATEWAY}/getVendorRequests?${params}`;
          console.log("url: ", url);

          return axios.get(url, {headers: getRequestHeaders()})
            .then((response) => {
              console.log("response: ", response);
              let results = {data: response.data.data, totalCount: response.data.totalCount};
              this.preResults = results;
              return results;
            }).catch(err => {
              console.log("error: ", err);
              alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false})
              let results = {data: [], totalCount: 0};
              this.preResults = results;
              return results;
            });
        }
      },
    });

    this.DataGridRef = React.createRef();
  }

  /**
    * Refresh the file view grid 
    */
  refresh = (currentFolder) => {
    this.preLoadingParams = '';
    this.toggleLoadingPanel(true);
    this.DataGridRef.current.instance.refresh();
    this.toggleLoadingPanel(false);
  }

  /**
    * Reset the file view grid layout
    */
  resetLayout = () => {
    this.preLoadingParams = '';
    this.DataGridRef.current.instance.state({});
    this.DataGridRef.current.instance.clearFilter("search");
  }

  /**
    * Set visibility of loading panel
    */
  toggleLoadingPanel = (status, message='Loading...') => {
    this.setState({
      loadPanelVisible: status,
      loadMessage: message
    });
  }

  /**
    * Export file rows from the grid view to excel
    */
  onExporting = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: this.DataGridRef.current.instance,
      worksheet: worksheet,
      customizeCell: function(options) {
        const excelCell = options;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      } 
    }).then(function() {
      workbook.xlsx.writeBuffer()
        .then(function(buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'VendorRequests.xlsx');
        });
    });
  }

  onRowDblClick = (e) => {
    console.log("onRowDblClick=", e.data)
    this.setState({
      vendorRequest: e.data,
      popupVisible: true
    })
  }

  hidePopup = () => {
    this.setState({
      popupVisible: false
    });
  }

  completed = () => {
    this.refresh(this.props.currentFolder);
  }

  tableHeight = () => {
    return window.innerHeight - 250;
  }

  render() {
    let popup = '';
     if (this.state.popupVisible){
      popup = <UpdateVendorRequest 
        popupVisible={this.state.popupVisible} 
        hidePopup={this.hidePopup} 
        completed={this.completed} 
        vendorRequest={this.state.vendorRequest}
        readOnly={!isTCUser()}
      />
    }
    else {
      popup = '';
    }

    return (
      <React.Fragment>
        {popup}
        <LoadPanel
          name='loadPanel'
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true}
        />
        <DataGrid
          ref={this.DataGridRef}
          dataSource={this.requestDS}
          remoteOperations={{groupPaging: false, paging: true, filtering: true, sorting: true, grouping: false, summary: false}}
          showBorders={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          headerFilter={{visible: true}}
          allowColumnResizing={true}
          columnResizingMode='widget'
          columnMinWidth={60}
          height={() => window.innerHeight - 250}
          stateStoring={{ enabled: true, type: 'localStorage', storageKey: 'VendorRequestGridLayout' }}
          scrolling={{ mode: 'standard', showScrollbar: 'always' }}
          onRowDblClick={this.onRowDblClick}
        >
          <GroupPanel visible={true} />
          <SearchPanel width ={300} visible={false} />
          <Grouping autoExpandAll={false} /> 
          <Selection mode="single" showCheckBoxesMode="none" />
          <FilterPanel visible={true} />
          <FilterBuilder fields={fields} groupOperations={groupOperations} customOperations={customOperations} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true} />
          <Editing
            mode="row"
            allowUpdating={false}
            allowDeleting={false}
            allowAdding={false}
            useIcons={true} />
          <Column dataField="RequestID" width={100} caption='Request ID' allowHeaderFiltering={false}/>
          <Column dataField="Status" width={120} caption='Status' />
          <Column dataField="RequestType" width={140} caption='Request Type' />
          <Column dataField="Vendor" width={140} caption='Vendor' visible={isTCUser()} />
          <Column dataField="MPID" width={200} caption='MPID' allowHeaderFiltering={false} />
          <Column dataField="Description" minWidth={350} caption='Description' allowHeaderFiltering={false} />
          <Column dataField="CreatedBy" width={190} caption='Requested By' />
          <Column dataField="CreatedDate" width={190} caption={requestedDate_header()} dataType="datetime" format='yyyy-MM-dd HH:mm:ss' defaultSortOrder="desc" />
          <Column dataField="AssignedTo" width={190} caption='Assigned To' />
          <Column dataField="Response" minWidth={250} caption='Response' allowHeaderFiltering={false} />
          <Column dataField="ClosedDate" width={180} caption={closedDate_header()} dataType="datetime" format='yyyy-MM-dd HH:mm:ss' />
        </DataGrid>
      </React.Fragment>  
    );
  }
}

