import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import AuthenticatedApp from "./CCS/AuthenticatedApp";
import reportWebVitals from './reportWebVitals.js';
import { runLocalhost, setupLocalhost, getAuthInfo } from './Utility/Utils.js'

function ClientApp() {
  if (runLocalhost()) {
    document.title = "PI Vendor Portal - Local";
    
    return (
      <>
        { setupLocalhost() }
        <App getAuthInfo={getAuthInfo} /> 
      </>
    );
  }
  return (
    <>
      <AuthenticatedApp />
    </>
  );
}

ReactDOM.render(
  <ClientApp />,
  document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
