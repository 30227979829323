import React from 'react';
import axios from "axios";
import { alert } from 'devextreme/ui/dialog';
import { LoadPanel } from 'devextreme-react/load-panel';
import HtmlEditor from 'devextreme-react/html-editor';
import { Toolbar, Item } from 'devextreme-react/toolbar';
import { isAdmin, getRequestHeaders } from '../Utility/Utils.js'

import './QuestionAndAnswer.css';
import QuestionAndAnswerEditor from '../HtmlEditor/QuestionAndAnswerEditor.js';

const editButtonOptions = {
  icon: 'edit',
  hint: 'Edit FAQs'
};

export default class QuestionAndAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      popupVisible: false,
      contents: ''
    };

    this.htmlEditor = React.createRef();
  }

  componentDidMount() {
    this.refresh();
  }

  showPopup = () => {
    this.setState({
      popupVisible: true
    });
  }

  hidePopup = () => {
    this.setState({
      popupVisible: false
    });
  }

  toggleLoadingPanel = (status, message='Loading...') => {
    this.setState({
      loadPanelVisible: status,
      loadMessage: message
    });
  }

  refresh = () => {
    let url = `${process.env.REACT_APP_API_GATEWAY}/getQuestionAndAnswer`;
    console.log("url: ", url);
    
    this.toggleLoadingPanel(true);
    axios.get(url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        const result = response.data.result
        if(result !== null && result !== "" && result[0] && result[0].Contents){
          this.setState({
            contents: result[0]['Contents']
          });
        }
      }).catch(err => {
        console.log(err);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
      .then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  render() {
    let popup = '';
    if (this.state.popupVisible){
      popup = <QuestionAndAnswerEditor 
        popupVisible={this.state.popupVisible} 
        hidePopup={this.hidePopup}
        refresh={this.refresh} />
    } else {
      popup = '';
    }

    return (
      <React.Fragment>
        {popup}
        <LoadPanel
            name="loadPanel"
            shadingColor="rgba(0,0,0,0.4)"
            visible={this.state.loadPanelVisible}
            message={this.state.loadMessage}
            showIndicator={true}
            shading={true}
            showPane={true} />
        <div className='home-container'>
          <h1 className='header'>Frequently Asked Questions</h1>
          <Toolbar>
            <Item location="after"
              widget="dxButton"
              visible={isAdmin()}
              onClick={this.showPopup} 
              options={editButtonOptions} />
          </Toolbar>
          <HtmlEditor value={this.state.contents} valueType="html" readOnly={true} ref={this.htmlEditor} height={window.innerHeight - 250} > 
          </HtmlEditor>
        </div>
      </React.Fragment>
    );
  }
}