export const companies=[
  {Id:'All',Text:'All'},
  {Id:'Baker Hughes',Text:'Baker Hughes'},
  {Id:'Enduro',Text:'Enduro'},
  {Id:'Entegra',Text:'Entegra'},
  {Id:'NDT',Text:'NDT'},
  {Id:'Novitech',Text:'Novitech'},
  {Id:'Onstream',Text:'Onstream'},
  {Id:'Pipecare',Text:'Pipecare'},
  {Id:'Pipelines 2 Data - P2D',Text:'Pipelines 2 Data - P2D'},
  {Id:'Pipetel',Text:'Pipetel'},
  {Id:'PureHM',Text:'PureHM'},
  {Id:'Rosen',Text:'Rosen'},
  {Id:'TC',Text:'TC'},
  {Id:'TDW',Text:'TDW'}
]