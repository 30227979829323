import React from 'react';
import axios from "axios";
import jwt from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import Loader from 'react-loader-spinner';

import './AuthenticatedApp.css'
import App from '../App.js'; 
import logo from '../Images/tc-energy-en.svg';
import { parseJwt, updateAuthInfo } from '../Utility/Utils.js'

export default class AuthenticatedApp extends React.Component {

  state = {
    authorized:false,
    pem:null,
    loginMessage:""
  }
  
  componentDidMount() {
    this.handleAuthentication();
  }

  getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
    
  async searchKeyList (keys, kid) {
    let foundKey = null;
    for (var key in keys) {
      let theKey = keys[key];
      console.log("before the searcH loop");
      console.log(theKey)
      if (theKey.kid === kid) {
        console.log("in searcH")
        foundKey = theKey;
        break;
      }
    }
    return foundKey;
  }

  async validateToken (authInfo) {
    try {
      console.log('Logged on: ' + this.state.authorized);
      let keys = JSON.parse(sessionStorage.getItem('keys'));
      if (!keys) {
        console.log('Getting public keys')
        //get cognito user pool public keys
        const instance = axios.create({
            baseURL: "https://cognito-idp.us-west-2.amazonaws.com"
        });

        const JWKSPath = "/" + process.env.REACT_APP_CCS_COGNITO_USER_POOL_ID + "/.well-known/jwks.json";
        const resp = await instance.get(JWKSPath, {
            method: 'GET',
            header: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            },
        });
        console.log(resp.data);
        keys = [...resp.data.keys];
        sessionStorage.setItem('keys', JSON.stringify(keys));
      }
      console.log("Keys: ");
      console.log(keys);
      let token = JSON.parse(authInfo).token;
      let headerData = token.split('.')[0];
      let headerText = Buffer.from(headerData,'base64').toString('ascii');
      let header = JSON.parse(headerText);
      console.log("Header:" + header);
      // validate kid in token is found in the list of public keys
      let foundKey = await this.searchKeyList(keys,header.kid);
      console.log("Search for kid: " + foundKey);
      if(!foundKey)  {
        throw new Error("invalid kid in token");
      }
      
      // validate token
      var pem = await jwkToPem(foundKey);
      console.log(pem);
      this.setState({pem:pem});
      jwt.verify(token, pem);
      console.log("Token was validated successfully.");
      //Get token body
      let tokenBodyData = token.split('.')[1];
      let tokenBodyText = Buffer.from(tokenBodyData,'base64').toString('ascii');
      let tokenBody = JSON.parse(tokenBodyText);
      console.log("Token Body:" + tokenBody)
      //validate Issuer
      let issuer = tokenBody.iss.substring(tokenBody.iss.lastIndexOf('/')+1);
      console.log("Issuer:" + issuer + " " + process.env.REACT_APP_CCS_COGNITO_USER_POOL_ID);
      if (issuer !== process.env.REACT_APP_CCS_COGNITO_USER_POOL_ID) {
        throw new Error("unknown issuer in token");
      }
      //validate aud claim
      console.log("Audience: " + tokenBody.aud + " " + process.env.REACT_APP_CCS_CLIENT_ID)
      if(tokenBody.aud !== process.env.REACT_APP_CCS_CLIENT_ID) {
        throw new Error("invalid internal client id in token");
      }
      //Check token_use claim
      if(tokenBody.token_use !== 'id') {
        throw new Error("Invalid token_use claim - must be 'id'");
      }
    } catch (err) {
      console.log(err);
      if (err.name === "TokenExpiredError" ) {
        sessionStorage.setItem('loginStatus','EXPIRED');
      } else {
        sessionStorage.setItem('loginStatus','INVALIDTOKEN');
      }
      this.setState({authorized:false});
      var ret = "ErrorMsg: " + err;
      return ret;
    }
    console.log("Authorized: " + JSON.parse(authInfo).claims.identities[0].userId);    
    this.setState({authorized:true});
    sessionStorage.setItem('loginStatus','COMPLETE');
    return 0
  }

  //cases: new, inprogress, expired, invalid, bad credentials, complete 
  async handleLoginRedirect(loginStatus) {
    console.log("Login status: " + loginStatus);
    if (loginStatus === 'none' || loginStatus === 'EXPIRED' || loginStatus === 'REDO') {
        console.log("New login status: INPROGRESS");
        sessionStorage.setItem('loginStatus','INPROGRESS');
        let ccsLoginURL=process.env.REACT_APP_CCS_LOGIN_URL + process.env.REACT_APP_CCS_MYSTATE + '%7C' + process.env.REACT_APP_CCS_COGNITO_USER_POOL_ID;
        window.location.replace(ccsLoginURL);
    } else if (loginStatus === 'COMPLETE') {
        let authInfo = await sessionStorage.getItem('authInfo');
        if (!authInfo) {
            console.log("New login status: INPROGRESS");
            sessionStorage.setItem('loginStatus','INPROGRESS');
            let ccsLoginURL=process.env.REACT_APP_CCS_LOGIN_URL + process.env.REACT_APP_CCS_MYSTATE + '%7C' + process.env.REACT_APP_CCS_COGNITO_USER_POOL_ID;
            window.location.replace(ccsLoginURL);            
        } else {
            console.log("Re-validating an existing id_token");
            let resp = await this.validateToken(authInfo);
            console.log(resp);
        }
    } else if (loginStatus === 'FAILED') {
      console.log("Login status unchanged");
      sessionStorage.removeItem('authInfo');
      this.setState({loginMessage:process.env.REACT_APP_CCS_FAILED_LOGIN_MSG})
    } else {
      sessionStorage.removeItem('authInfo');
      console.log("Login status unchanged");
    }
    return 0
  }

  async handleAuthentication() {
    let env = process.env.REACT_APP_CCS_ENV;
    console.log("Common Cognito Service: " + env);

    if (env !== 'PROD') {
      document.title = "PI Vendor Portal - " + env;
    }
    
    let authInfo;
    let token = this.getParameterByName('id_token');
    if (token) {
      // token on the URL - assume it is new and add it local session, replacing previous one (if it is there)
      // grab the ccsSessionKey from the URL as well, will need it to refresh our id token
      let ccsSessionKey = this.getParameterByName('ccsSessionKey');
      authInfo = {
        token: token,
        claims: parseJwt(token),
        ccsSessionKey: ccsSessionKey
      };
      sessionStorage.setItem('authInfo', JSON.stringify(authInfo));
      sessionStorage.setItem('loginStatus', 'NEW');
      //remove it from the URL
      let obs = window.location.href.substring(0, window.location.href.indexOf('?'));
      window.history.pushState({},"continue", obs);
    }
    //Handle a CCS errorMsg
    let errorMsg=this.getParameterByName('errorMsg');
    if (errorMsg) {
      //For now, treating all errors as a failed login attempt
      sessionStorage.setItem('loginStatus', 'FAILED');
      //remove it from the URL
      let obs = window.location.href.substring(0, window.location.href.indexOf('?'));
      window.history.pushState({},"failed login", obs);
    }
    let loginStatus = sessionStorage.getItem('loginStatus');
    if (!loginStatus) {
        await this.handleLoginRedirect('none');
    } else if (loginStatus === 'NEW') {
        let resp = await this.validateToken(sessionStorage.getItem('authInfo'));
        console.log(resp);
    } else {
        await this.handleLoginRedirect(loginStatus);
    }
    console.log("Authentication Process Completed!");
  }

  getAuthInfo(action, token=null) {
    if (action === "GET") {
      try {
        console.log("Call made to getAuthInfo: GET");
        //let token = JSON.parse(this.state.authInfo).token;
        let authInfo = sessionStorage.getItem('authInfo');
        if (!authInfo) {
            throw new Error("authInfo missing");
        }
        jwt.verify(JSON.parse(authInfo).token, this.state.pem);
        console.log("Token is still valid");
        console.log(JSON.parse(authInfo));
        return JSON.parse(authInfo);
      } catch (err) {
        console.log("ErrorMsg: " + err);
        if (err.name === "TokenExpiredError" ) {
            sessionStorage.setItem('loginStatus','EXPIRED');
            this.handleLoginRedirect('EXPIRED');
        } else if (err.message === "authInfo missing"){
            sessionStorage.setItem('loginStatus','REDO');
            this.handleLoginRedirect('REDO');            
        } else {
            sessionStorage.setItem('loginStatus','INVALIDTOKEN');
        }
        this.setState({authorized:false});
        return null;
      }
    }
    if (action === "REFRESH" && token){
      try {
        jwt.verify(token, this.state.pem);
        console.log("New token is valid");
        updateAuthInfo(token);
      } catch (err) {
        console.log("ErrorMsg: " + err);
        if (err.name === "TokenExpiredError" ) {
            sessionStorage.setItem('loginStatus','EXPIRED');
            this.handleLoginRedirect('EXPIRED');
        } else if (err.message === "authInfo missing"){
            sessionStorage.setItem('loginStatus','REDO');
            this.handleLoginRedirect('REDO');            
        } else {
            sessionStorage.setItem('loginStatus','INVALIDTOKEN');
        }
        this.setState({authorized:false});
        return null;
      }
    }
    return null;
  }

  render() {
    if (this.state.authorized) {
        return ( 
          <App getAuthInfo={this.getAuthInfo.bind(this)} />
        );
    }
    let loginStatus = sessionStorage.getItem('loginStatus');
    if (loginStatus === 'INPROGRESS') {
      return (
        <div className="loginpage">
          <img src={logo} alt={"logo"} className="loginpagelogo"/>
          <div className="login-spinner">
            <Loader type="Oval" color="green" secondaryColor="blue" height={100} width={100} />
          </div>
        </div>
      );  
    }
    let ccsLoginURL=process.env.REACT_APP_CCS_LOGIN_URL + process.env.REACT_APP_CCS_MYSTATE  + '%7C' + process.env.REACT_APP_CCS_COGNITO_USER_POOL_ID;
    return (
        <div className="loginpage">
          <img src={logo} alt={"logo"} className="loginpagelogo"/>
          <div className="loginpagemsg">{this.state.loginMessage}</div>
          <a href={ccsLoginURL} className="loginbutton" target="_self" title="Login">Login</a>
        </div>
    );
  }

}