import React from 'react';
import { Toolbar, Item } from 'devextreme-react/toolbar';

import './DeliverableTab.css';
import AddDeliverable from '../Popups/AddDeliverable.js';
import NotificationBuilder from '../Popups/NotificationBuilder.js';
import DeliverableView from '../Views/DeliverableView.js';
import { isReadOnly, isTCUser, getLoginFolder } from '../Utility/Utils.js'
import { companies } from '../Data/companies.js';

function renderCompanyLabel() {
  return <div><b>Vendor:</b></div>;
}

// options for toolbar buttons at top right of DeliverableView grid
const refreshButtonOptions = {
  icon: 'refresh',
  hint: 'Refresh grid view'
};

const addButtonOptions = {
  icon: 'plus',
  hint: 'Upload a file'
};

const downloadButtonOptions = {
  icon: 'download',
  hint: 'Download selected files'
};

const exportExcelButtonOptions = {
  icon: 'exportxlsx',
  hint: 'Export grid view to excel'
}

const resetLayoutButtonOptions = {
  icon: 'revert',
  hint: 'Reset grid view layout and filter'
};

const emailNotificationButtonOptions = {
  icon: 'email',
  hint: 'Register email notification'
};

export default class DeliverableTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupVisible: false,
      notificationVisible: false,
      currentFolder: getLoginFolder()
    };

    this.deliverableView = React.createRef();
    
    this.checkBoxOptions = {
      text: 'Show 500 Most Recent Deliverables',
      value: true,
      onValueChanged: (e) => this.handleShowAllValueChanged(e)
    };

    this.selectBoxOptions = {
      width: 200,
      items: isTCUser()? companies : [],
      valueExpr: 'Id',
      displayExpr: 'Text',
      value: this.state.currentFolder,
      onValueChanged: (e) => this.handleFolderValueChanged(e)
    };

    sessionStorage.setItem('showAll', false);
  }

  handleShowAllValueChanged = (e) => {
    sessionStorage.setItem('showAll', e.value? false : true);
  }

  /**
    * Company filter in top left of UI is changed
    * Grid will update to reflect files in the company that was selected folder
    */
  handleFolderValueChanged = (e) => {
    if (isTCUser()) {
      console.log("selected folder: " + e.value);
      this.setState({
        currentFolder: e.value
      });
      this.refresh();
    }
  }

  /**
    * Hide AddDeliverable popup
    */
  hidePopup = () => {
    this.setState({
      popupVisible: false
    });
  }

  /**
    * Show AddDeliverable popup
    */
  showPopup = () => {
    if (!isReadOnly()) {
      this.setState({
        popupVisible: true
      });
    }
  }
  
  hideNotificationPopup = () => {
    this.setState({
      notificationVisible: false
    });
  }

  showNotificationPopup = () => {
    this.setState({
      notificationVisible: true
    });
  }

  /**
    * Refresh data grid with files
    */
  refresh = () => {
    this.deliverableView.current.refresh(this.state.currentFolder);
  }

  /**
  * Reset data grid layout
  */
  resetLayout = () => {
    this.deliverableView.current.resetLayout();
  }

  downloadFiles = () => {
    this.deliverableView.current.downloadFiles();
  }

  /**
  * Call the file grid view to export rows to excel
  */
  exportExcel = () => {
    this.deliverableView.current.onExporting();
  }

  /**
  * Refresh file view after a successful file upload
  */
  completed = (destination) => {
    if (isTCUser()) {
      if (this.state.currentFolder === destination || this.state.currentFolder === 'All') {
        this.deliverableView.current.refresh(this.state.currentFolder);
      }
    } else {
      this.deliverableView.current.refresh(destination);
    }
  }

  render() {
    let popup = '';
    if (this.state.popupVisible){
      popup = <AddDeliverable 
        popupVisible={this.state.popupVisible} 
        hidePopup={this.hidePopup} 
        completed={this.completed} />
    } else if (this.state.notificationVisible) {
      popup = <NotificationBuilder 
        popupVisible={this.state.notificationVisible} 
        hidePopup={this.hideNotificationPopup} />
    } else {
      popup = '';
    }
    return (
      <React.Fragment>
        {popup}
        <Toolbar>
          <Item location="left"
            locateInMenu="never"
            visible={isTCUser()}
            render={renderCompanyLabel} />
          <Item location="left"
            locateInMenu="never"
            visible={isTCUser()}
            widget="dxSelectBox"
            options={this.selectBoxOptions} />
         {/*  {<Item location="left"
            locateInMenu="never"
            widget="dxCheckBox"
            options={this.checkBoxOptions} />} */}
          <Item location="after"
            widget="dxButton"
            onClick={this.exportExcel} 
            options={exportExcelButtonOptions} />
          <Item location="after"
            widget="dxButton"
            onClick={this.showNotificationPopup} 
            options={emailNotificationButtonOptions} />
          <Item location="after"
            widget="dxButton"
            onClick={this.downloadFiles} 
            options={downloadButtonOptions} />
          <Item location="after"
            widget="dxButton"
            onClick={this.refresh} 
            options={refreshButtonOptions} />
          <Item location="after"
            widget="dxButton"
            onClick={this.resetLayout} 
            options={resetLayoutButtonOptions} />
          <Item location="after"
            widget="dxButton"
            visible={!isReadOnly()}
            onClick={this.showPopup} 
            options={addButtonOptions} />
        </Toolbar>
        <br/>
        <DeliverableView ref={this.deliverableView} currentFolder={this.state.currentFolder}/>
      </React.Fragment>
    );
  }
}

