import React from 'react';
import axios from "axios";
import { LoadPanel } from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import DropDownBox from 'devextreme-react/drop-down-box';
import TextArea from 'devextreme-react/text-area';
import DataGrid, { Column, FilterRow, Paging, Pager } from 'devextreme-react/data-grid';
import Popup from 'devextreme-react/popup';
import Validator, { RequiredRule, CustomRule } from "devextreme-react/validator";
import { alert } from 'devextreme/ui/dialog';
import ValidationGroup from 'devextreme-react/validation-group';
import LocalStore from 'devextreme/data/local_store';
import DataSource from 'devextreme/data/data_source';

import './SubmitVendorRequest.css'
import { getRequestHeaders, getLoginVendor } from '../Utility/Utils.js'

const requestTypes = [
  {"Type": "Application", "Description": "Reporting of issues or bugs related to the operation of the Vendor Portal application."},
  {"Type": "Data", "Description": "Requests, questions, or clarifications related to PLQ data, pipe tally data, or other ILI project data."},
  {"Type": "Specifications", "Description": "Questions or clarifications related to TC Energy engineering specifications."},
  {"Type": "User Support", "Description": "All other support requests, including the provisioning of Vendor Portal access for colleagues."}
]

export default class SubmitVendorRequest extends React.Component {
  constructor(props){
    super(props);

		this.publisher = getLoginVendor();
    this.loadingcount = 0;
    this.assignedTo = '';
    this.assignedToEmail = '';
      
    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',

      selectedMPIDs: [],
      requestType: '',
      description: '',
      mpidDropdownDataSource: new DataSource({
        store: new LocalStore({
          key: 'MPID',
          data: [],
          name: 'mpidDropdownData'
        })
      })
    };

    this.mpidDropDownBoxRef = React.createRef();
    this.typeDropDownBoxRef = React.createRef();
  }
  
  componentWillUnmount() {
    this.state.mpidDropdownDataSource.store().clear();
  }

  /**
  * When the upload popup is opened in the UI - data is retrieved for drop downs in the popup
  */
  getPopupData = () => {
    this.getMPIDs(this.getAssignee);
  }

  /**
  * Get all available MPIDs using API Gateway and Lambda 
  * and map them to the mpidSelectionDataSource
  */
  getMPIDs = (callback) => {
    // API Gateway url to the get-mpid lambda
    let mpids_url = `${process.env.REACT_APP_API_GATEWAY}/getMPIDs?vendor=${this.publisher}`;
    console.log("url: ", mpids_url);

    this.toggleLoadingPanel(true);
    
    // clear the data source
    this.state.mpidDropdownDataSource.store().clear();

    // API call to retrieve deliverable types
    axios.get(mpids_url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        // MPIDs
        const mpids = response.data.MPIDs
        if(mpids !== null && mpids !== "") {
          // map MPID to mpidSelectionDataSource
          mpids.forEach((mpid) => {
            if (mpid.AssessmentPathName)
              this.state.mpidDropdownDataSource.store().insert(mpid);
          })
        }
        
        this.toggleLoadingPanel(false);

        if (callback && typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        console.log(err);
        this.toggleLoadingPanel(false);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
  }

  getAssignee = (callback) => {
    let url = `${process.env.REACT_APP_API_GATEWAY}/getVendorRequestAssignees?default=yes`;
    console.log("url: ", url);
    this.toggleLoadingPanel(true);

    axios.get(url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        const assignees = response.data.result
        if(assignees !== null && assignees !== ""){
          this.assignedTo = assignees[0]['UserName'];
          this.assignedToEmail = assignees[0]['UserEmail'];
        }

        this.toggleLoadingPanel(false);

        if (callback && typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        console.log(err);
        this.toggleLoadingPanel(false);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
  }

  /**
  * File has been successfully uploaded
  */
  completed = () => {
    this.props.completed();
    this.props.hidePopup();
  }

  /**
  * Set visibility of loading panel
  */
  toggleLoadingPanel = (status, message='Loading...') => {
    this.loadingcount = this.loadingcount + (status? 1 : -1);
    this.loadingcount = this.loadingcount < 0? 0: this.loadingcount;
    
    let newstatus = this.loadingcount > 0? true : false;

    this.setState({
      loadPanelVisible: newstatus,
      loadMessage: message
    });
  }

  /**
  * Submit Due Date Extension Request
  */
  handleSubmit = (e) => {
    let result = this.validationGroup.instance.validate();
    // if invalid data then exit method
    if (!result.isValid) {
      return false;
    } 

    // API Gateway url pointing to upload lambda
    let url = `${process.env.REACT_APP_API_GATEWAY}/saveVendorRequest`;
    // authentication info
    let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));

    // metadata tags for file
    let body = {
      "SelectedMPIDs": this.state.selectedMPIDs,
      "RequestType": this.state.requestType,
      "Description": this.state.description,
      "Vendor": this.publisher,
      "AssignedTo": this.assignedTo,
      "AssignedToEmail": this.assignedToEmail,
      "CreatedBy": authInfo.claims.name,
      "CreatedByEmail": authInfo.claims.email
    }

    console.log("onsubmit body=", body);
    
    this.toggleLoadingPanel(true, 'Sending...');
    axios.post(url, body, {headers: getRequestHeaders()})
      .then((result) => {
        this.completed();
        alert({message:'<b style="color:rgb(92,184,92)";>Request sent successfully.</b>', showTitle:false});
      }).catch(error => {
          console.log(error);
          alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>An error occured while sending the request.</b>', showTitle:false});
      })
      .then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  onMPIDSelectionChanged = (e) => {
    this.setState({
      selectedMPIDs: (e.selectedRowKeys.length && e.selectedRowKeys) || []
    });

    // update dropdownbox input field
    let field = this.mpidDropDownBoxRef.current.instance.field();
    field.value= this.state.selectedMPIDs;
  }

  onRequestTypeSelectionChanged = (e) => {
    this.setState({
      requestType: (e.selectedRowKeys.length && e.selectedRowKeys[0]['Type']) || ''
    });

    // update dropdownbox input field
    let field = this.typeDropDownBoxRef.current.instance.field();
    field.value= this.state.requestType;

    this.typeDropDownBoxRef.current.instance.close();
  }

  onMPIDValueChanged =(e) => {
    this.setState({
      selectedMPIDs: []
    });
  }

  onTypeValueChanged =(e) => {
    this.setState({
      requestType: ''
    });
  }

  checkMPID = (e) => {
    if (this.state.requestType === 'Data') {
      if (this.state.selectedMPIDs && this.state.selectedMPIDs.length > 0) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  checkRequestType = (e) => {
    if (this.state.requestType === '') 
      return false;
    else 
      return true;
  }

  mpidGridRender = () => {
    return (
      <DataGrid
        dataSource={this.state.mpidDropdownDataSource}
        scrolling={{ mode: 'standard', showScrollbar: 'always' }}
        selection={{ mode: 'multiple', allowSelectAll: false, showCheckBoxesMode: 'always' }}
        selectedRowKeys={this.state.selectedMPIDs}
        onSelectionChanged={this.onMPIDSelectionChanged}>
        <Paging enabled={true} pageSize={5} />
        <Pager
          visible={true}
          showPageSizeSelector={false}
          showNavigationButtons={true}
          showInfo={true} />
        <FilterRow visible={true} />
        <Column dataField="MPID" width={100} caption='MPID' allowHeaderFiltering={true} />
        <Column dataField="AssessmentPathName" caption='Assessment Path' allowHeaderFiltering={false} />
      </DataGrid>
    );
  }

  typeGridRender = () => {
    return (
      <DataGrid
        dataSource={requestTypes}
        selection={{ mode: 'single'}}
        wordWrapEnabled={true}
        showBorders={true}
        showRowLines={true}
        onSelectionChanged={this.onRequestTypeSelectionChanged}>
        <FilterRow visible={false} />
        <Column dataField="Type" width={100} caption='Type' allowHeaderFiltering={false} />
        <Column dataField="Description" caption='Description' allowHeaderFiltering={false} />
      </DataGrid>
    );
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name="loadPanel"
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true}
        />
        <Popup
          width={800}
          height={760}
          showTitle={true}
          title='Submit Vendor Request'
          dragEnabled={false}
          hideOnOutsideClick={false}
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.2)"
          visible={this.props.popupVisible}
          onHiding={this.props.hidePopup}
          onShown={this.getPopupData}>
          <ValidationGroup ref={ref => this.validationGroup = ref}>
            <div id="popup" className="vr-submit-popup-details">
              <div className="dx-fieldset">
                <div className="dx-field">
                  <div className="vr-submit-popup-option">
                    <span>MPID(s):</span>
                    <DropDownBox
                      ref = {this.mpidDropDownBoxRef}
                      displayExpr="MPID"
                      valueExpr="MPID"
                      value=""
                      deferRendering={false}
                      showClearButton={true}
                      contentRender={this.mpidGridRender} 
                      onValueChanged={(e) => this.onMPIDValueChanged(e)}>
                      <Validator>
                        <CustomRule 
                          ignoreEmptyValue={false}
                          message="Required"
                          validationCallback={(e) => this.checkMPID(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </DropDownBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="vr-submit-popup-option">
                    <span>Request Type:</span>
                    <DropDownBox
                      ref = {this.typeDropDownBoxRef}
                      displayExpr="Type"
                      valueExpr="Type"
                      value=""
                      deferRendering={false}
                      showClearButton={true}
                      contentRender={this.typeGridRender} 
                      onValueChanged={(e) => this.onTypeValueChanged(e)}>
                      <Validator>
                        <CustomRule 
                          ignoreEmptyValue={false}
                          message="Required"
                          validationCallback={(e) => this.checkRequestType(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </DropDownBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="vr-submit-popup-option">
                    <span>Description:</span>
                    <TextArea 
                      height={400} 
                      maxLength={4000} 
                      onValueChanged={(e) => this.setState({description: e.value})} >
                      <Validator>
                        <RequiredRule />
                      </Validator>
                    </TextArea>
                  </div>
                </div>
              </div>
            </div>
          </ValidationGroup>
          <div className="vr-submit-popup-buttons">
            <Button
              icon="upload"
              text="Submit"
              width={210}
              height={44}
              elementAttr={{class: 'favorites'}}
              onClick={this.handleSubmit} />
          </div>
        </Popup>
      </React.Fragment>
    );
  }
}
