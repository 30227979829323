import React from 'react';
import { MemoryRouter, Routes, Route, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Menu, { Item } from 'devextreme-react/menu';
import { validateAuthGroup, getLoginUser } from './Utility/Utils.js'
import { RefreshToken } from './Scheduler/Scheduler.js'

import './App.css';
import Home from './Pages/Home'
import InlineInspection from './Pages/InlineInspection';
import QuestionAndAnswer from './Pages/QuestionAndAnswer';
import usericon from './Images/user.png';

export default class App extends React.Component {
  render() {
    let authInfo = this.props.getAuthInfo("GET");
    let isValid = validateAuthGroup();
    if (!authInfo || !isValid) {
      return (
        <div className="App">
          <header className="App-header">
            You are Not Authorized! Access Denied.
          </header>
        </div>
      );
    }

    return (
      <React.Fragment>
        {RefreshToken()}
        <MemoryRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="inspection" element={<InlineInspection />} />
              <Route path="faq" element={<QuestionAndAnswer />} />
              <Route path="*" element={<Home /> } />
            </Route>
          </Routes>
        </MemoryRouter>
      </React.Fragment>
    );
  }
}

function Layout() {
  let user = getLoginUser();
  let location = useLocation();
  let navigate = useNavigate();

  let pagename = 'Home Page';
  if (location.pathname === '/inspection') {
    pagename = 'Inline Inspection'
  }
  else if (location.pathname === '/faq') {
    pagename = 'FAQs'
  }
    
  const onItemClick = (e) => {
    console.log('path=', e.itemData.text)
  
    if (e.itemData.text === 'Home Page') {
      navigate('/');
    }
    else if (e.itemData.text === 'Inline Inspection') {
      navigate('/inspection');
    }
    else if (e.itemData.text === 'FAQs') {
      navigate('/faq');
    }
  }

  return (
    <>
      <div className="titlebar">
        <table className="navigation">
          <tbody>
            <tr>
              <td className="menubar">
                <table>
                  <tbody>
                    <tr>
                      <td className="menu">
                        <Menu onItemClick={onItemClick}>
                          <Item text="" icon="contentlayout">
                            <Item text="Home Page" icon="home">
                            </Item>
                            <Item text="Inline Inspection" icon="exportselected">
                            </Item>
                            <Item text="FAQs" icon="help">
                            </Item>
                          </Item>
                        </Menu>
                      </td>
                      <td className="logo">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="title">
                <span>Vendor Portal - {pagename}</span>
              </td>
              <td className="user"><img src={usericon} className="usericon" />{user}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Outlet />
    </>
  )
}

