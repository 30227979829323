import React from 'react';
import axios from "axios";
import { alert } from 'devextreme/ui/dialog';
import Popup from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { HtmlEditor, Toolbar, Item } from 'devextreme-react/html-editor';
import { getRequestHeaders } from '../Utility/Utils.js'

import './AnnouncementEditor.css';

const sizeValues = [ "8pt", "10pt", "12pt", "13pt", "14pt", "15pt", "16pt", "18pt", "24pt", "36pt" ];
const fontValues = [ "Arial", "Calibri", "Georgia", "Tahoma", "Times New Roman", "Verdana" ];
const headerValues = [ false, 1, 2, 3, 4, 5 ];

export default class AnnouncementEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      announcement: ''
    };
  }

  valueChanged = (e) => {
    let announcement = this.state.announcement;
    announcement.Message = e.value;

    this.setState({
      announcement: announcement
    });
  }

  onPopupShowing = () => {
    this.load() ;
  }

  onPopupHiding = () => {
    this.setState({
      loadPanelVisible: false,
      loadMessage: 'Loading...'
    });

    this.props.hidePopup();
    this.props.refresh();
  }

  toggleLoadingPanel = (status, message='Loading...') => {
    this.setState({
      loadPanelVisible: status,
      loadMessage: message
    });
  }

  completed = () => {
    this.props.refresh();
    this.props.hidePopup();
  }

  load = () => {
    let url = `${process.env.REACT_APP_API_GATEWAY}/getAnnouncements`;
    console.log("url: ", url);
    
    this.toggleLoadingPanel(true);
    axios.get(url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        const result = response.data.result
        if(result !== null && result !== "" && result[0]){
          this.setState({
            announcement: result[0]
          });
        }
      }).catch(err => {
        console.log(err);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
      .then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  save = (e) => {
    // API Gateway url pointing to upload lambda
    let url = `${process.env.REACT_APP_API_GATEWAY}/saveAnnouncement`;
    // authentication info
    let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));
    let userEmail = authInfo.claims.email;

    let body = {
      "id": this.state.announcement.ID,
      "message": this.state.announcement.Message,
      "user": userEmail
    }
    
    this.toggleLoadingPanel(true, 'Saving...');
    axios.post(url, body, {headers: getRequestHeaders()})
      .then((result) => {
        this.toggleLoadingPanel(false);
        this.completed();
        alert({message:'<b style="color:rgb(92,184,92)";>Updates saved successfully.</b>', showTitle:false});
      }).catch(error => {
        console.log(error);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>An error occured while saving data, try again or contact support.</b>', showTitle:false});
      })
      .then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name="loadPanel"
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true} />
        <Popup
          width={1400}
          height={850}
          showTitle={true}
          title='Edit Announcements'
          dragEnabled={false}
          hideOnOutsideClick={false}
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.2)"
          visible={this.props.popupVisible}
          onHiding={this.onPopupHiding}
          onShown={this.onPopupShowing} >
          <div className='a-popup-editor'>
            <HtmlEditor value={this.state.announcement.Message} valueType="html" onValueChanged={this.valueChanged} height={'680px'}>
              <Toolbar multiline={true} > 
                  <Item name="undo" />
                  <Item name="redo" />
                  <Item name="separator" />
                  <Item name="size" acceptedValues={sizeValues} />
                  <Item name="font" acceptedValues={fontValues} />
                  <Item name="separator" />
                  <Item name="header" acceptedValues={headerValues} />
                  <Item name="separator" />
                  <Item name="bold" />
                  <Item name="italic" />
                  <Item name="strike" />
                  <Item name="underline" />
                  <Item name="separator" />
                  <Item name="increaseIndent" />
                  <Item name="decreaseIndent" />
                  <Item name="alignLeft" />
                  <Item name="alignCenter" />
                  <Item name="alignRight" />
                  <Item name="alignJustify" />
                  <Item name="separator" />
                  <Item name="orderedList" />
                  <Item name="bulletList" />
                  <Item name="separator" />
                  <Item name="color" />
                  <Item name="background" />
                  <Item name="separator" />
                  <Item name="link" />
                  <Item name="separator" />
                  <Item name="clear" />
                  <Item name="codeBlock" />
                  <Item name="blockquote" />
                  <Item name="separator" />
                  <Item name="insertTable" />
                  <Item name="deleteTable" />
                  <Item name="insertRowAbove" />
                  <Item name="insertRowBelow" />
                  <Item name="deleteRow" />
                  <Item name="insertColumnLeft" />
                  <Item name="insertColumnRight" />
                  <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>
          </div>
          
          <div className="a-popup-buttons">
            <Button
              icon="save"
              text="Save"
              width={210}
              height={44}
              elementAttr={{class: 'favorites'}}
              onClick={(e) => this.save(e)} />
          </div>
        </Popup>
      </React.Fragment>
    );
  }
}