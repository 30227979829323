import React from 'react';
import axios from "axios";
import LocalStore from 'devextreme/data/local_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Editing, Grouping, GroupPanel, SearchPanel, FilterPanel, FilterRow, Paging, Pager} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alert } from 'devextreme/ui/dialog';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { getRequestHeaders, getLoginFolder } from '../Utility/Utils.js'
import './DueDateExtensionView.css';

function requestedDueDate_header() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Requested Due Date (' + timezone + ')';
}

function approvedDueDate_header() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Approved Due Date (' + timezone + ')';
}

function requestedDate_header() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Requested Date (' + timezone + ')';
}

function actionedDate_header() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Actioned Date (' + timezone + ')';
}

export default class DueDateExtensionView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      vendorRequestDS: new DataSource({
        store: new LocalStore({
          key: "Key",
          data: [],
          name: 'localVendorRequestData'
        })
      })
    };

    this.DataGridRef = React.createRef();
  }

  componentDidMount() {
    this.refresh(getLoginFolder());
  }

  componentWillUnmount() {
    this.state.vendorRequestDS.store().clear();
  }
  
  /**
    * Refresh the file view grid 
    */
   refresh = (currentFolder) => {
    let url = `${process.env.REACT_APP_API_GATEWAY}/getDueDateExtensions?vendor=${currentFolder}`;
    console.log("url: ", url);

    this.toggleLoadingPanel(true);
    
    this.state.vendorRequestDS.store().clear();
    axios.get(url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        const requests = response.data.result
        if(requests !== null && requests !== ""){
            console.log(requests);
            requests.forEach((request) => {
              this.state.vendorRequestDS.store().insert(request);
            })
        }
      }).catch(err => {
        console.log(err);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false})
      })
      .then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  /**
    * Reset the file view grid layout
    */
   resetLayout = () => {
    this.DataGridRef.current.instance.state({});
    this.DataGridRef.current.instance.clearFilter("search");
  }

  /**
    * Set visibility of loading panel
    */
  toggleLoadingPanel = (status, message='Loading...') => {
    this.setState({
      loadPanelVisible: status,
      loadMessage: message
    });
  }

  /**
    * Export file rows from the grid view to excel
    */
  onExporting = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: this.DataGridRef.current.instance,
      worksheet: worksheet,
      customizeCell: function(options) {
        const excelCell = options;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      } 
    }).then(function() {
      workbook.xlsx.writeBuffer()
        .then(function(buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DeliverableExtensionRequests.xlsx');
        });
    });
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name='loadPanel'
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true}
        />
        <DataGrid
          ref={this.DataGridRef}
          dataSource={this.state.vendorRequestDS}
          showBorders={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          headerFilter={{ visible: true }}
          allowColumnResizing={true}
          columnResizingMode='widget'
          columnMinWidth={60}
          height={() => window.innerHeight - 250}
          stateStoring={{ enabled: true, type: 'localStorage', storageKey: 'DuedateGridLayout' }}
          scrolling={{ mode: 'standard', showScrollbar: 'always' }}
        >
          <GroupPanel visible={true} />
          <SearchPanel width ={300} visible={false} />
          <Grouping autoExpandAll={false} />  

          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true} />
          <Editing
            mode="row"
            allowUpdating={false}
            allowDeleting={false}
            allowAdding={false}
            useIcons={true} />
          <Column dataField="RequestID" width={120} caption='Request ID' allowHeaderFiltering={false}/>
          <Column dataField="RequestStatus" width={120} caption='Status' />
          <Column dataField="MPID" width={100} caption='MPID' allowHeaderFiltering={false} />
          <Column dataField="SubType" width={140} caption='Subtype' />
          <Column dataField="AssetOrLob" width={140} caption='Asset/LOB' />
          <Column dataField="AssessmentPathName" width={220} caption='Assessment Path' allowHeaderFiltering={false} />
          <Column dataField="ILIReportTypeDis" width={140} caption='Deliverable Type' />
          <Column dataField="ILIReportSubTypeDis" width={220} caption='Deliverable Subtype' />
          <Column dataField="RequestedDueDate" width={200} caption={requestedDueDate_header()} dataType="datetime" format='yyyy-MM-dd HH:mm:ss' />
          <Column dataField="ApprovedDueDate" width={220} caption={approvedDueDate_header()} dataType="datetime" format='yyyy-MM-dd HH:mm:ss' />
          <Column dataField="RequestReason" width={140} caption='Request Reason' />
          <Column dataField="RequestDetails" caption='Request Details' allowHeaderFiltering={false} minWidth={150} />
          <Column dataField="CreatedBy" width={210} caption='Requested By' allowHeaderFiltering={false} />
          <Column dataField="CreatedDate" width={230} caption={requestedDate_header()} dataType="datetime" format='yyyy-MM-dd HH:mm:ss' defaultSortOrder="desc" />
          <Column dataField="ActionedDate" width={180} caption={actionedDate_header()} dataType="datetime" format='yyyy-MM-dd HH:mm:ss' />
        </DataGrid>
      </React.Fragment>  
    );
  }
}

