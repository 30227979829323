import React from 'react';
import Popup from 'devextreme-react/popup';
import LoadPanel from 'devextreme-react/load-panel';
import { alert } from 'devextreme/ui/dialog';
import FilterBuilder from 'devextreme-react/filter-builder';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import DataGrid, { Editing, Column, Paging, Pager} from 'devextreme-react/data-grid';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule, CustomRule } from "devextreme-react/validator";
import { getRequestHeaders, isTCUser, getLoginVendor } from '../Utility/Utils.js'
import axios from "axios";

import './NotificationBuilder.css'

// const TAB_SIZE = 4;

// function formatTextValue(value, spaces = TAB_SIZE) {
//   if (value && Array.isArray(value[0])) {
//     return `[${getLineBreak(spaces)}${value.map((item) => (Array.isArray(item[0]) ? formatTextValue(item, spaces + TAB_SIZE) : JSON.stringify(item))).join(`,${getLineBreak(spaces)}`)}${getLineBreak(spaces - TAB_SIZE)}]`;
//   }

//   return JSON.stringify(value);
// }

// function getLineBreak(spaces) {
//   return `\r\n${new Array(spaces + 1).join(' ')}`;
// }

function formatValue(value) {
  if (value && Array.isArray(value[0])) {
    return `(${value.map((item) => (Array.isArray(item[0]) ? formatValue(item) : JSON.stringify(item))).join(` `)})`;
  }
  return JSON.stringify(value);
}

function formatQuery(query) {
  return query
    .replaceAll("[", "(")
    .replaceAll("]", ")")
    .replaceAll("\"","")
    .replaceAll(","," ");
}

function escapeSpecialCharacters(value) {
  return value.replaceAll("'", "''");
}

const groupOperations = ['and', 'or'];

const customOperations = [
  {
    name: "IsNull",
    caption: "Is Null",
    dataTypes: ["string"],
    hasValue: false,
    icon: "isblank",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "is null"];
    }
  },
  {
    name: "IsNotNull",
    caption: "Is Not Null",
    dataTypes: ["string"],
    hasValue: false,
    icon: "isnotblank",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "is not null"];
    }
  },
  {
    name: "Equals",
    caption: "Equals",
    dataTypes: ["string"],
    hasValue: true,
    icon: "equal",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "=", "'" + escapeSpecialCharacters(filterValue) + "'"];
    }
  },
  {
    name: "NotEquals",
    caption: "Does not equal",
    dataTypes: ["string"],
    hasValue: true,
    icon: "notequal",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "!=", "'" + escapeSpecialCharacters(filterValue) + "'"];
    }
  },
  {
    name: "Contains",
    caption: "Contains",
    dataTypes: ["string"],
    hasValue: true,
    icon: "contains",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "like", "'%" + escapeSpecialCharacters(filterValue) + "%'"];
    }
  },
  {
    name: "NotContains",
    caption: "Does not Contain",
    dataTypes: ["string"],
    hasValue: true,
    icon: "contains",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "not like", "'%" + escapeSpecialCharacters(filterValue) + "%'"];
    }
  },
  {
    name: "StartsWith",
    caption: "Starts with",
    dataTypes: ["string"],
    hasValue: true,
    icon: "startswith",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "like", "'" + escapeSpecialCharacters(filterValue) + "%'"];
    }
  },
  {
    name: "EndsWith",
    caption: "Ends with",
    dataTypes: ["string"],
    hasValue: true,
    icon: "endswith",
    calculateFilterExpression: function(filterValue, field) {
        return [field.dataField, "like", "'%" + escapeSpecialCharacters(filterValue) + "'"];
    }
  }
];

const fields = [
  {
    caption: 'File Name',
    dataField: 'Name',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'MPID',
    dataField: 'MPID',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'Country',
    dataField: 'Country',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'Assessment Path',
    dataField: 'Assessment_Path',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'Asset/Lob',
    dataField: 'AssetOrLob',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'SubType',
    dataField: 'SubType',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'Deliverable Type',
    dataField: 'DeliverableTypeDispName',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'Deliverable SubType',
    dataField: 'DeliverableSubTypeDispName',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }, {
    caption: 'Created By',
    dataField: 'Created_By',
    dataType: 'string',
    filterOperations: ['IsNull', 'IsNotNull', 'Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith']
  }
];

const types = [
  'Immediately',
  'Daily'
];

export default class NotificationBuilder extends React.Component{
    constructor(props){
      super(props);

      this.state = {
        loadPanelVisible: false,
        loadMessage: 'Loading...',
        frequency: '',
        filter_name: '',
        filter: [],
        filterExpression: '',
        filterDataSource: []
      };

      this.vendorClause = " and (Vendor = '" + getLoginVendor() + "')";
    }
    
    onPopupShowing = () => {
      this.onLoading();
    }

    onPopupHiding = () => {
      this.setState({
        loadPanelVisible: false,
        loadMessage: 'Loading...',
        filter: [],
        filterExpression: '',
        filterDataSource: []
      });

      this.props.hidePopup();
    }

    toggleLoadingPanel = (status, message='Loading...') => {
      this.setState({
        loadPanelVisible: status,
        loadMessage: message
      });
    }

    onLoading = () => {
      // authentication info
      let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));
      let userEmail = authInfo.claims.email;

      // API Gateway url to the get-mpid lambda
      let url = `${process.env.REACT_APP_API_GATEWAY}/getNotificationFilters?user=${userEmail}`;
      console.log("url: ", url);

      this.toggleLoadingPanel(true);
      
      // API call to retrieve deliverable types
      axios.get(url, {headers: getRequestHeaders()})
        .then(response => {
          console.log(response);
          let filters = response.data.result
          if(filters !== null && filters !== "") {
            this.setState({
              filterDataSource:filters
            })
          }
        }).catch(err => {
          console.log(err);
          alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
        })
        .then(() => {
          this.toggleLoadingPanel(false);
        })
    }

    onSaving = (e) => {
      let result = this.validationGroup.instance.validate();
      // if invalid data then exit method
      if (!result.isValid) {
        return false;
      } 

      if (!this.state.filterExpression || this.state.filterExpression === 'null') {
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>The notification filter is empty!</b>', showTitle:false});
        return false;
      }

      // API Gateway url pointing to upload lambda
      let url = `${process.env.REACT_APP_API_GATEWAY}/saveNotificationFilter`;
      // authentication info
      let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));
      let userEmail = authInfo.claims.email;

      let body = {
        "Name": this.state.filter_name,
        "Filter": JSON.stringify(this.state.filter),
        "FilterExpression": this.constructFilterExpression(this.state.filterExpression),
        "Frequency": this.state.frequency,
        "User": userEmail
      }
      console.log("filter body: ", body);
      
      this.toggleLoadingPanel(true, 'Saving...');
      axios.post(url, body, {headers: getRequestHeaders()})
        .then(() => {
          this.onLoading();
        }).catch(error => {
            console.log(error);
            alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>An error occured while sending the request.</b>', showTitle:false});
        })
        .then(() => {
          this.toggleLoadingPanel(false);
        })
    }

    onDeleting = (e) => {
      console.log('onDeleting:', e);
      let delete_url = `${process.env.REACT_APP_API_GATEWAY}/deleteNotificationFilter`;
      let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));
      let userEmail = authInfo.claims.email;

      let body = {
        "ID": e.key,
        "User": userEmail
      }

      axios.post(delete_url, body, {headers: getRequestHeaders()})
        .then(response => {
        }).catch(err => {
          console.log(err);
          alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>An error occured while sending the request.</b>', showTitle:false});
        }).then(() => {
          this.onLoading();
        })
    }

    constructFilterExpression = (expression) => {
      if (isTCUser() || expression.endsWith(this.vendorClause)) {
        return expression;
      } else {
        // external users should only allow to search documents from the same vendor
        return expression + this.vendorClause;
      }
    }

    checkNameIsUnique = (name) => {
      return this.state.filterDataSource.every((filter) => {
        return name.toUpperCase() !== filter.Name.toUpperCase();
      });
    }

    onFilterValueChanged = (e) => {
      this.setState({
        filter: e.value,
        //filterValue: formatTextValue(e.component.option('value')),
        filterExpression: formatQuery(formatValue(e.component.getFilterExpression()))
      });
    }
  
    onNameValueChanged = (e) => {
      this.setState({
        filter_name: e.value,
      });
    }

    onTypeValueChanged = (e) => {
      this.setState({
        frequency: e.value,
      });
    }

    onViewFilter = (data) => {
      this.setState({
        filter: JSON.parse(data.Filter),
        filterExpression: data.FilterExpression
      });
    }

    renderViewButtonCell = (rowData) => {
      return <a href="/#" onClick={() => this.onViewFilter(rowData.data)} className="dx-link dx-icon-find dx-link-icon" title="View"> </a>
    }

    renderFilterCell = (rowData) => {
      if (rowData.data.FilterExpression.endsWith(this.vendorClause)) {
        return rowData.data.FilterExpression.replaceAll(this.vendorClause, "");
      } else {
        return rowData.data.FilterExpression;
      }
    }

    render() {
      const { filter } = this.state;
      return (
        <React.Fragment>
          <LoadPanel
            name="loadPanel"
            shadingColor="rgba(0,0,0,0.4)"
            visible={this.state.loadPanelVisible}
            message={this.state.loadMessage}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
          <Popup
            width={1200}
            height={700}
            showTitle={true}
            title='Email Notifications'
            dragEnabled={false}
            hideOnOutsideClick={false}
            shading={true}
            shadingColor="rgba(0, 0, 0, 0.2)"
            visible={this.props.popupVisible}
            onHiding={this.onPopupHiding}
            onShown={this.onPopupShowing}>
            <div className="filter-container">
              <FilterBuilder
                fields={fields}
                value={filter}
                groupOperations={groupOperations}
                customOperations={customOperations}
                onValueChanged={this.onFilterValueChanged} />
              <ValidationGroup ref={ref => this.validationGroup = ref}>
                <div className="parent">
                  <div className="child">
                    <span>Name:</span>
                  </div>
                  <div className="child">
                    <TextBox 
                      width='300px'
                      showClearButton={true}
                      placeholder="Enter name here..."
                      onValueChanged={this.onNameValueChanged}
                    >
                      <Validator>
                        <RequiredRule />
                        <CustomRule 
                          ignoreEmptyValue={true}
                          message="This filter name has already been used!"
                          validationCallback={(e) => this.checkNameIsUnique(e.value)}
                          reevaluate={true} />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className="child">
                    <span>Frequency:</span>
                  </div>
                  <div className="child">
                    <SelectBox 
                      items={types}
                      showClearButton={true}
                      width={200}
                      placeholder="Choose frequency..."
                      onValueChanged={this.onTypeValueChanged}
                    >
                      <Validator>
                        <RequiredRule />
                      </Validator>
                    </SelectBox>
                  </div>
                  <div className="child">
                    <Button
                      text="Subscribe"
                      type="default"
                      onClick={this.onSaving} />   
                  </div>
                </div>
              </ValidationGroup>
              <div className="dx-clearfix"></div>
            </div>
            <DataGrid
              keyExpr="ID"
              dataSource={this.state.filterDataSource}
              showBorders={true}
              showRowLines={true}
              rowAlternationEnabled={true}
              allowColumnReordering={false}
              headerFilter={{ visible: false }}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              onRowRemoving={this.onDeleting}
              height={350}>
              <Paging defaultPageSize={5} />
              <Pager
                visible={true}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                allowedPageSizes={[5, 10, 15]}
                showInfo={true} />
              <Editing
                mode="row"
                allowUpdating={false}
                allowDeleting={true}
                allowAdding={false}
                useIcons={true} />
              <Column dataField="Name" width={200} caption='Name' />
              <Column caption='Filter' cellRender={this.renderFilterCell} />
              <Column dataField="Frequency" width={100} caption='Frequency' />
              <Column dataField="Created_Date" width={150} caption='Created Date (MST)' dataType="datetime" format='yyyy-MM-dd HH:mm:ss' />
              <Column width={50} caption='' alignment="center" cellRender={this.renderViewButtonCell} />
            </DataGrid>
          </Popup>
        </React.Fragment>
      );
    }
}