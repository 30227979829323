import React from 'react'
import axios from "axios";
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Validator, { RequiredRule, CustomRule, AsyncRule } from "devextreme-react/validator";
import ValidationGroup from 'devextreme-react/validation-group';
import { alert } from 'devextreme/ui/dialog';
import LocalStore from 'devextreme/data/local_store';
import DataSource from 'devextreme/data/data_source';
import './AddDeliverable.css'
import { getRequestHeaders } from '../Utility/Utils.js'

export default class AddFile extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      fileAddPopupVisible: false, 
      file: null,
      allowedFileExtensions: [],
      masterPlanSubtypes: [],
      deliverableType: {
        displayName: '',
        type: ''
      },
      deliverableSubtype: {
        displayName: '',
        type: ''
      },
      deliverableSubtypeVisible: false,
      deliverableTypeDataSource: new DataSource({
        paginate: false,
        store: new LocalStore({
          key: 'Type',
          data: [],
          name: 'deliverableTypeData',
        })
      }),
      deliverableSubtypeDataSource: new DataSource({
        paginate: false,
        store: new LocalStore({
          key: 'Type',
          data: [],
          name: 'deliverableSubtypeData',
        })
      }),
      ilireceivedate: '',
      iliReceiveDateVisible: false
    };
  }

  componentWillUnmount() {
    this.state.deliverableTypeDataSource.store().clear();
    this.state.deliverableSubtypeDataSource.store().clear();
  }

  // Calls the API Gateway to retrieve Report Type and MPID data
  getAddFilePopupData = (e) => {
    // if deliverable type data source is not already populated, retrieve data
    if (this.state.deliverableTypeDataSource.items().length === 0) {
      console.log("No deliverableType present - retrieving");
      this.getDeliverableTypes();
    }
  }

  handleAddFilePopupHidden = () => {
    this.setState({
      file: null,
      deliverableType: {
        displayName: '',
        type: ''
      },
      deliverableSubtype: {
        displayName: '',
        type: ''
      },
      deliverableSubtypeVisible: false,
      ilireceivedate: '',
      iliReceiveDateVisible: false
    });
    this.props.hideAddFilePopup();
  }

  /**
    * Get all deliverable types and deliverably subtypes using API Gateway and Lambda 
    * and map them to the deliverableTypeDataSource and deliverableSubtypeDataSource respectively
    */
    getDeliverableTypes = (e) => {
      // API Gateway url to the getReporttype lambda
      let deliverables_url =  `${process.env.REACT_APP_API_GATEWAY}/getReporttypes`;
      console.log("url: ", deliverables_url);

      this.toggleLoadingPanel(true);

      // clear the data source
      this.state.deliverableTypeDataSource.store().clear();
      // arrays used to store all deliverable types that are parent types
      // and all deliverable parent types that are available after filtering based on mpid subtype (respectively)
      let allParentTypes = [];
      let filteredParentTypes = [];
      // API call to retrieve deliverable types
      axios.get(deliverables_url, {headers: getRequestHeaders()})
        .then(response => {
          console.log(response);
          // deliverable types 
          const deliverabletypes = response.data.ReportTypes
         
          if(deliverabletypes !== null && deliverabletypes !== ""){
              console.log(deliverabletypes);
              deliverabletypes.forEach((deliverable) => {
                // convert the PermittedTypes property of the deliverable into two separate properties 
                deliverable.FileExtensions = JSON.parse(deliverable.PermittedTypes).FileExtensions;
                deliverable.MasterPlanSubtypes = JSON.parse(deliverable.PermittedTypes).MasterPlanSubTypes;
                delete deliverable.PermittedTypes;
                // deliverable type must be active to be added to the datasources
                // if the deliverable type is 'parent' type it is added to the deliverableTypeDataSource
                // if deliverable type is a subtype it is added to the deliverableSubtypeDataSource
                if (deliverable.Active) {
                  if (deliverable.ParentType == null) {
                    this.state.deliverableTypeDataSource.store().insert(deliverable);
                  }
                  else {
                    this.state.deliverableSubtypeDataSource.store().insert(deliverable);
                    // if parent type of deliverable subtype is not yet in allParentTypes array, add it
                    if (!allParentTypes.includes(deliverable.ParentType)) {
                      allParentTypes.push(deliverable.ParentType);
                    }
                  }
                }
              })

              // allow deliverable subtype if it has no Master Plan subtypes specified or if it does
              // specify Master Plan Subtypes and the selected MPID's subtype is included
              this.state.deliverableSubtypeDataSource.filter(((deliverableSub) => {
                if (deliverableSub.MasterPlanSubtypes.length === 0 || this.props.mpSubtype === '' || deliverableSub.MasterPlanSubtypes.includes(this.props.mpSubtype)) {
                  // if it has not yet been added, add the parent type of the subtype deliverable to the filteredParentTypes array
                  if (!filteredParentTypes.includes(deliverableSub.ParentType)) {
                    filteredParentTypes.push(deliverableSub.ParentType);
                  }
                  return true;
                }
                else {
                  return false;
                }
                }) 
              );

              // filter the available deliverable types and subtypes based on the MPID subtype
              // allow deliverable type if it is a filtered parent type or is not a parent type at all
              // and if it has no Master Plan subtypes specified or if it does
              // specify Master Plan Subtypes - the selected MPID's subtype is included
              this.state.deliverableTypeDataSource.filter(((deliverable) => {
                return ((filteredParentTypes.includes(deliverable.Type) || !allParentTypes.includes(deliverable.Type)) && (deliverable.MasterPlanSubtypes.length === 0 || this.props.mpSubtype === '' || deliverable.MasterPlanSubtypes.includes(this.props.mpSubtype)));
              }));
              // load the deliverable subtype data source before the deliverable types
              this.state.deliverableSubtypeDataSource.load().then(() => {
                // load the deliverable data sources to see new items
                this.state.deliverableTypeDataSource.load();
              });
          }
        }).catch(err => {
          console.log(err);
          alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
        })
        .then(() => {
          this.toggleLoadingPanel(false);
        }) 
    }

     /**
    * When selection is made from the deliverable type drop down in 
    * the upload popup set the deliverable type in the state, clear the deliverable subtype selection in state
    * and set visibility of the deliverable subtype in state (reflected in UI)
    */
    handleDeliverableTypeChanged = (e) => {
      this.state.deliverableTypeDataSource.store().byKey(e.value).then(
        (deliverable) => { 
          this.filterDeliverableSubtypes(deliverable.Type);
          this.setState({
            deliverableType: {
              displayName: deliverable.DisplayName,
              type: deliverable.Type 
            },
            deliverableSubtype: {
              displayName: '',
              type: ''
            },
            deliverableSubtypeVisible: (this.state.deliverableSubtypeDataSource.items().length > 0) ? true : false,
            iliReceiveDateVisible:  (deliverable.Type === "FieldReport") ? true : false,
            allowedFileExtensions: deliverable.FileExtensions,
            masterPlanSubtypes: deliverable.MasterPlanSubtypes
          })
      },
        (error) => {
        }
      );
    }

    /**
    * When selection is made from the deliverable subtype drop down in 
    * the upload popup set the deliverable subtype selection in state
    */
    handleDeliverableSubtypeChanged = (e) => {
      this.state.deliverableSubtypeDataSource.store().byKey(e.value).then(
        (deliverableSub) => { 
          this.setState({
            deliverableSubtype:{
              displayName: deliverableSub.DisplayName,
              type: deliverableSub.Type
            },
            allowedFileExtensions: deliverableSub.FileExtensions,
            masterPlanSubtypes: deliverableSub.MasterPlanSubtypes
          })
      },
        (error) => {
        }
      );
    }

    /**
    * Filter options in the deliverable subtype drop down of the upload popup based
    * on the selection made in the deliverable type drop down (and the MPID subtype)
    */
    filterDeliverableSubtypes = (parentType) => {
      // allow deliverable subtype if it has no Master Plan subtypes specified or if it does
      // specify Master Plan Subtypes and the selected MPID's subtype is included.
      // The deliverable subtype must have it's parent type selected from the deliverable type drop down
      this.state.deliverableSubtypeDataSource.filter(((deliverableSub) => {
        return  ((deliverableSub.MasterPlanSubtypes.length === 0 || this.props.mpSubtype === '' || deliverableSub.MasterPlanSubtypes.includes(this.props.mpSubtype))  
                && deliverableSub.ParentType === parentType);
        }) 
      );
      this.state.deliverableSubtypeDataSource.load();
    }

    /**
    * Check that deliverable subtype selection is a subtype of the deliverable type selected
    * in the upload popup
    */
    checkDeliverableSubtype = (e) => {
      let validSubtype = false;
      if (this.state.deliverableSubtypeVisible){
        console.log("Checking deliverable sub type")
        this.state.deliverableSubtypeDataSource.store().byKey(e.value).then(
          (deliverableSub) => { 
            console.log("Deliverable Parent Subtype: ", deliverableSub.ParentType);
          this.state.deliverableTypeDataSource.store().byKey(deliverableSub.ParentType).then(
            (deliverable) => { 
              console.log("Deliverable type: ", deliverable.Type);
            if (deliverable.Type === deliverableSub.ParentType && deliverable.DisplayName === this.state.deliverableType.displayName) {
              console.log("deliverable subtype parent matches deliverable type");
              validSubtype = true;
            }
            else {
              console.log("deliverable subtype and deliverable discovered but do not match");
            }
          },
            (error) => {
              console.log("Cannot find deliverable");
            }
          );
        },
          (error) => {
            console.log("Cannot find deliverable sub type");
          }
        );
      }
      else {
        console.log("subtype not visible so no actual validation: ", this.state.deliverableSubtype);
        validSubtype = true;
      }
      
      return validSubtype;
    }

    checkILIReceiveDate = (e) => {
      let valid = false;
      if (this.state.iliReceiveDateVisible){
        console.log("Checking ili receive date:", e.value)
        if (e.value && e.value <= new Date()) {
          valid = true;
        }
      } else {
        valid = true;
      }

      return valid;
    }

    /**
    * Set visibility of loading panel
    */
    toggleLoadingPanel = (status, message='Loading...') => {
      this.setState({
        loadPanelVisible: status,
        loadMessage: message
      });
    }

    /**
    * When a file is selected from the file explorer
    * add it to the component state information
    */
    handleFileChanged = (e) => {
      // console.log("File event: ", e);
      if (e.value && e.value[0]) {
        this.setState({
          file: e.value[0]
        });
      } else {
        this.setState({
          file: null
        });
      }
    }
  /**
  * Check that the file type of selected file is valid based on the
  * deliverable type/subtype
  */
  checkFileType = (fileName) => {
    let validFileType = false;
    let fileType = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    if (fileType !== undefined && fileType !== null) {
      validFileType = this.state.allowedFileExtensions.length > 0 ? this.state.allowedFileExtensions.includes(fileType) : true
    }
    return validFileType;
  }

  /**
  * When the Add File button is clicked on
  * the add file popup
  * Create a file object and pass it to the upload popup 
  * where will be added to the add files gridview
  */
  handleAddFile = (e) => {
    let result = this.addFileValidationGroup.instance.validate();
    if (!result.isValid)  {
      console.log("handleAddFile isValied: ", result.isValid);
      return false;
    }

    // file object
    let file = {
      fileObj: this.state.file,
      fileName: this.state.file.name,
      deliverableType: {
        displayName: this.state.deliverableType.displayName,
        type: this.state.deliverableType.type
      },
      deliverableSubtype: {
        displayName: this.state.deliverableSubtype.displayName,
        type: this.state.deliverableSubtype.type
      }, 
      size: this.state.file.size,
      masterPlanSubtypes: this.state.masterPlanSubtypes,
      ilireceivedate: this.state.ilireceivedate? (new Date(this.state.ilireceivedate)).toISOString() : ""
    };

    console.log("handleAddFile file: ", file);

    // pass the file object to the upload popup
    // to be added to the add files grid view
    this.props.addFile(file);
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name="loadPanel"
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true} />
        <Popup
          width={500} 
          height={550}
          showTitle={true}
          title='Add File'
          dragEnabled={false}
          hideOnOutsideClick={false}
          visible={this.props.addFilePopupVisible}
          onHiding={this.handleAddFilePopupHidden}
          onShown={this.getAddFilePopupData}
          >
            <ScrollView width='100%' height='100%' >
              <ValidationGroup ref={ref => this.addFileValidationGroup = ref}>
                <div id="popup2" className="d-popup-details">
                <div className="addFile-option">
                  <span>Deliverable Type:</span>
                  <SelectBox
                    displayExpr="DisplayName"
                    valueExpr="Type"
                    dataSource={this.state.deliverableTypeDataSource}
                    onValueChanged={(e) => this.handleDeliverableTypeChanged(e)}
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"DisplayName"}
                    searchTimeout={200}
                    minSearchLength={0}
                    showDataBeforeSearch={false} 
                    wrapItemText={true}
                    >
                    <Validator>
                      <RequiredRule />
                    </Validator>
                  </SelectBox>
                  <div className="d-popup-option" style={{ display: (this.state.deliverableSubtypeVisible ? 'block' : 'none' )}}>
                    <span>Deliverable Subtype:</span>
                    <SelectBox
                      displayExpr="DisplayName"
                      valueExpr="Type"
                      dataSource={this.state.deliverableSubtypeDataSource}
                      value={this.state.deliverableSubtype.type}
                      onValueChanged={(e) => this.handleDeliverableSubtypeChanged(e)}
                      searchEnabled={true}
                      searchMode={"contains"}
                      searchExpr={"DisplayName"}
                      searchTimeout={200}
                      minSearchLength={0}
                      showDataBeforeSearch={false} >
                      <Validator>
                        <CustomRule 
                        ignoreEmptyValue={false}
                        message="Value selected must be a subtype of the deliverable type"
                        validationCallback={(e) => this.checkDeliverableSubtype(e)}
                        reevaluate={true}
                        />
                      </Validator>
                    </SelectBox>
                  </div>
                  <div className="d-popup-option" style={{ display: (this.state.iliReceiveDateVisible ? 'block' : 'none' )}}>
                    <span>ILI Receive Date:</span>
                    <DateBox
                      type="date"
                      useMaskBehavior={true}
                      showClearButton={true}
                      displayFormat="yyyy-MM-dd"
                      onValueChanged={(e) => this.setState({ilireceivedate: e.value})} >
                      <Validator>
                        <CustomRule 
                          ignoreEmptyValue={false}
                          message="ILI Receive Date is required or can not be a future date"
                          validationCallback={(e) => this.checkILIReceiveDate(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </DateBox>
                    <span className="disclaimer">By submitting this date, you acknowledge that it is accurate within the deliverable</span>
                  </div>
                  <br/>
                  <span>File:</span>
                  <div className="addFile-fileuploader-container">
                    <FileUploader
                      selectButtonText="Select File" 
                      labelText="" 
                      accept="*" 
                      uploadMode="useForm" 
                      onValueChanged={(e) => this.handleFileChanged(e)} >
                      <Validator>
                        <RequiredRule />
                        <CustomRule 
                          ignoreEmptyValue={true}
                          message="A file with the same file name has already been selected for addition"
                          validationCallback={(e) => this.props.checkExistingFileNamesToAdd(e.value[0].name)}
                          reevaluate={true}
                        />
                        <AsyncRule 
                          ignoreEmptyValue={true}
                          message="A file with the same file name has already been uploaded"
                          validationCallback={(e) => this.props.checkExistingS3Files(e.value[0].name)}
                        />
                        <CustomRule 
                          ignoreEmptyValue={true}
                          message="A file with the same file type (deliverable type or deliverable subtype) has already been selected for addition"
                          validationCallback={(e) => this.props.checkExistingFileDeliverableTypesToAdd(this.state.deliverableType, this.state.deliverableSubtype)}
                          reevaluate={true}
                        />
                        <CustomRule 
                          ignoreEmptyValue={true}
                          message={`The file type is invalid for the selected deliverable. The file must be of the following type(s): ${this.state.allowedFileExtensions.join(', ')}`}
                          validationCallback={(e) => this.checkFileType(e.value[0].name)}
                          reevaluate={true}
                        />
                      </Validator>
                    </FileUploader>
                  </div>
                </div>
                <div className="addFile-property-buttons">
                  <Button
                    icon="plus"
                    text="Add File"
                    width={210}
                    height={44}
                    elementAttr={{class: 'favorites'}}
                    onClick={(e) => this.handleAddFile(e)} />
                </div>
              </div>
              <br/>
              </ValidationGroup>
            </ScrollView>
          </Popup>
      </React.Fragment>
    );
  }
}