import React from 'react';
import axios from "axios";
import { alert } from 'devextreme/ui/dialog';
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel } from 'devextreme-react/load-panel';
import HtmlEditor from 'devextreme-react/html-editor';
import List from 'devextreme-react/list';
import { Toolbar, Item } from 'devextreme-react/toolbar';
import { isAdmin, getRequestHeaders } from '../Utility/Utils.js'

import './Home.css';
import AnnouncementEditor from '../HtmlEditor/AnnouncementEditor.js';
import AddCommonFile from '../Popups/AddCommonFile.js';

const editAnnouncementsButton = {
  icon: 'edit',
  hint: 'Edit announcements'
};

const addReferenceButton = {
  icon: 'plus',
  hint: 'Add reference'
};

function renderAnnouncements() {
  return <h2 className='title'>Announcements</h2>;
}

function renderReferences() {
  return <h2 className='title'>Reference Guides</h2>;
}

function renderUrl(data) {
  return <a href="/#" onClick={ () => download(data.FileKey) }>{ data.DisplayTitle }</a>;
}

function download(key) {
  let url = `${process.env.REACT_APP_API_GATEWAY}/downloadFile`;
  let body = {
    "key": key
  }
  console.log("url: ", url);

  axios.post(url, body, {headers: getRequestHeaders()})
    .then(response => {
      console.log(response);
      var iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = response.data;
      document.body.appendChild(iframe);
    }).catch(err => {
      console.log(err);
      alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Failed to download file, try again or contact support.</b>', showTitle:false});
    })
}

export default class InlineInspection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      announcementPopupVisible: false,
      referencePopupVisible: false,
      announcement: '',
      references: []
    };

    this.htmlEditor = React.createRef();
  }

  componentDidMount() {
    this.getAnnouncements(this.getCommonFiles);
  }

  showAnnouncementPopup = () => {
    this.setState({
      announcementPopupVisible: true
    });
  }

  showReferencePopup = () => {
    this.setState({
      referencePopupVisible: true
    });
  }

  hideAnnouncementPopup = () => {
    this.setState({
      announcementPopupVisible: false
    });
  }

  hideReferencePopup = () => {
    this.setState({
      referencePopupVisible: false
    });
  }

  toggleLoadingPanel = (status, message='Loading...') => {
    this.setState({
      loadPanelVisible: status,
      loadMessage: message
    });
  }

  getAnnouncements = (callback) => {
    let url = `${process.env.REACT_APP_API_GATEWAY}/getAnnouncements`;
    console.log("url: ", url);
    
    this.toggleLoadingPanel(true);
    axios.get(url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        const result = response.data.result
        if(result !== null && result !== "" && result[0] && result[0].Message){
          this.setState({
            announcement: result[0]['Message']
          });
        }

        this.toggleLoadingPanel(false);

        if (callback && typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        console.log(err);
        this.toggleLoadingPanel(false);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
  }

  getCommonFiles = (callback) => {
    let url = `${process.env.REACT_APP_API_GATEWAY}/getCommonFiles`;
    console.log("url: ", url);
    
    this.toggleLoadingPanel(true);
    axios.get(url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        this.setState({
          references: response.data.data
        });

        this.toggleLoadingPanel(false);

        if (callback && typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        console.log(err);
        this.toggleLoadingPanel(false);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
  }

  deleteReference = (e) => {
    e.cancel = new Promise(async (resolve, reject) => {
      if (isAdmin()) {
        let result = confirm("Are you sure you want to delete this file?", "Delete File");
        result.then((dialogResult) => {
          if (dialogResult) {
            let itemData = e.itemData;
            let key = itemData.FileKey;
            let delete_url = `${process.env.REACT_APP_API_GATEWAY}/deleteFile`;
            let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));
            let userEmail = authInfo.claims.email;
  
            let body = {
              "key": key,
              "user": userEmail
            }
        
            console.log("url: ", delete_url);
            
            this.toggleLoadingPanel(true, 'Processing...');
            axios.post(delete_url, body, {headers: getRequestHeaders()})
              .then(response => {
                console.log(response);
                this.toggleLoadingPanel(false);
                this.getCommonFiles();
                resolve();
              }).catch(err => {
                console.log(err);
                this.toggleLoadingPanel(false);
                alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Failed to delete file, try again or contact support.</b>', showTitle:false});
                reject();
              })
          } else {
            reject();
          }
        });
      } else {
        resolve();
      } 
    })
  }

  render() {
    let announcementPopup = '';
    if (this.state.announcementPopupVisible){
      announcementPopup = <AnnouncementEditor 
        popupVisible={this.state.announcementPopupVisible} 
        hidePopup={this.hideAnnouncementPopup}
        refresh={this.getAnnouncements} />
    } else {
      announcementPopup = '';
    }

    let referencePopup = '';
    if (this.state.referencePopupVisible){
      referencePopup = <AddCommonFile 
        popupVisible={this.state.referencePopupVisible} 
        hidePopup={this.hideReferencePopup}
        refresh={this.getCommonFiles} />
    } else {
      referencePopup = '';
    }

    return (
      <React.Fragment>
        {announcementPopup}
        {referencePopup}
        <LoadPanel
            name="loadPanel"
            shadingColor="rgba(0,0,0,0.4)"
            visible={this.state.loadPanelVisible}
            message={this.state.loadMessage}
            showIndicator={true}
            shading={true}
            showPane={true} />
        <div className='home-container'>
          <h1 className='header'>Welcome to the TC Energy Vendor Portal!</h1>
          <div className='announcement' >
            <Toolbar>
              <Item location="left"
                locateInMenu="never"
                render={renderAnnouncements} />
              <Item location="after"
                widget="dxButton"
                visible={isAdmin()}
                onClick={this.showAnnouncementPopup} 
                options={editAnnouncementsButton} />
            </Toolbar>
            <HtmlEditor 
              ref={this.htmlEditor} 
              value={this.state.announcement} 
              valueType="html" 
              readOnly={true} 
              height={window.innerHeight - 250} >
            </HtmlEditor>
          </div>
          <div className='reference'>
            <Toolbar>
              <Item location="left"
                locateInMenu="never"
                render={renderReferences} />
              <Item location="after"
                widget="dxButton"
                visible={isAdmin()}
                onClick={this.showReferencePopup} 
                options={addReferenceButton} />
            </Toolbar>
            <List
              dataSource={this.state.references}
              height={window.innerHeight - 250}
              allowItemDeleting={isAdmin()}
              itemDeleteMode="toggle"
              onItemDeleting={this.deleteReference}
              selectionMode="single"
              itemRender={renderUrl} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}