import React from 'react';
import axios from "axios";
import { LoadPanel } from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import TextArea from 'devextreme-react/text-area';
import DataGrid, { Column, FilterRow, Paging, Pager } from 'devextreme-react/data-grid';
import Popup from 'devextreme-react/popup';
import Validator, { RequiredRule, CustomRule } from "devextreme-react/validator";
import { alert } from 'devextreme/ui/dialog';
import ValidationGroup from 'devextreme-react/validation-group';
import LocalStore from 'devextreme/data/local_store';
import DataSource from 'devextreme/data/data_source';

import './DueDateExtensionRequest.css'
import { getRequestHeaders, getLoginVendor } from '../Utility/Utils.js'
import { validMPIDProjectStatusList } from '../Data/validMPIDProjectStatusList';

function requstedDueDate_label() {
  var timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
 
  return 'Requested Deliverable Due Date (' + timezone + '):';
}

const requestReasonOptions = [
  'Complex Analysis Required',
  'Degraded Data Plan',
  'Feature Density',
  'Optimize Work Schedule',
  'Wall Thickness Discrepancy Listing',
  'Other'
]

export default class DueDateExtensionRequest extends React.Component {
  constructor(props){
    super(props);

		this.publisher = getLoginVendor();
		this.mpidValidationMessage = '';
		this.MPID = '';
		this.vendor = '';
		this.projectStatus = '';
    this.loadingcount = 0;
      
    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      deliverableSubtypeVisible: false,
      requestDetailsVisible: false,
      assessmentPath: '',
      subtype: '',
      assetOrLob: '',
      deliverabletypes: [],
      deliverableType: {
        displayName: '',
        type: ''
      },
      deliverableSubtype: {
        displayName: '',
        type: ''
      },
      requestedDueDate: '',
      requestReason: '',
      requestDetails: '',
      impactedProjectDetails: '',
      impactedMPIDs: [],
      mpidValidationMessage: '',

      mpidSelectionDataSource: new DataSource({
        store: new LocalStore({
          key: 'MPID',
          data: [],
          name: 'mpidSelectionData'
        })
      }),
      mpidDropdownDataSource: new DataSource({
        store: new LocalStore({
          key: 'MPID',
          data: [],
          name: 'mpidDropdownData'
        })
      }),
      deliverableTypeDataSource: new DataSource({
        paginate: false,
        store: new LocalStore({
          key: 'Type',
          data: [],
          name: 'deliverableTypeData',
        })
      }),
      deliverableSubtypeDataSource: new DataSource({
        paginate: false,
        store: new LocalStore({
          key: 'Type',
          data: [],
          name: 'deliverableSubtypeData',
        })
      }),
    };

    this.dropDownBoxRef = React.createRef();
  }
  
  componentWillUnmount() {
    this.state.mpidSelectionDataSource.store().clear();
    this.state.mpidDropdownDataSource.store().clear();
    this.state.deliverableTypeDataSource.store().clear();
    this.state.deliverableSubtypeDataSource.store().clear();
  }

  /**
  * When the upload popup is opened in the UI - data is retrieved for drop downs in the popup
  */
  getPopupData = () => {
    this.getMPIDs(this.getDeliverableTypes);
  }

  /**
  * Get all available MPIDs using API Gateway and Lambda 
  * and map them to the mpidSelectionDataSource
  */
  getMPIDs = (callback) => {
    // API Gateway url to the get-mpid lambda
    let mpids_url = `${process.env.REACT_APP_API_GATEWAY}/getMPIDs?vendor=${this.publisher}`;
    console.log("url: ", mpids_url);

    this.toggleLoadingPanel(true);
    
    // clear the data source
    this.state.mpidSelectionDataSource.store().clear();
    this.state.mpidDropdownDataSource.store().clear();

    // API call to retrieve deliverable types
    axios.get(mpids_url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        // MPIDs
        const mpids = response.data.MPIDs
        if(mpids !== null && mpids !== "") {
          // map MPID to mpidSelectionDataSource
          mpids.forEach((mpid) => {
            this.state.mpidSelectionDataSource.store().insert(mpid);
            if (mpid.AssessmentPathName)
              this.state.mpidDropdownDataSource.store().insert(mpid);
          })
        }
        this.toggleLoadingPanel(false);

        if (callback && typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        console.log(err);
        this.toggleLoadingPanel(false);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
  }

  /**
  * Get all deliverable types and deliverably subtypes using API Gateway and Lambda 
  * and map them to the deliverableTypeDataSource and deliverableSubtypeDataSource respectively
  */
  getDeliverableTypes = (callback) => {
    // API Gateway url to the getReporttype lambda
    let deliverables_url =  `${process.env.REACT_APP_API_GATEWAY}/getReporttypes`;
    console.log("url: ", deliverables_url);

    this.toggleLoadingPanel(true);

    // API call to retrieve deliverable types
    axios.get(deliverables_url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);

        let types = response.data.ReportTypes;
        if(types !== null && types !== "") {
          let allowedTypes = [];

          types.forEach((deliverable) => {
            // convert the PermittedTypes property of the deliverable into 3 separate properties 
            deliverable.FileExtensions = JSON.parse(deliverable.PermittedTypes).FileExtensions;
            deliverable.MasterPlanSubtypes = JSON.parse(deliverable.PermittedTypes).MasterPlanSubTypes;
            deliverable.DueDateChangeRequest = JSON.parse(deliverable.PermittedTypes).DueDateChangeRequest;
            delete deliverable.PermittedTypes;

            if (deliverable.DueDateChangeRequest === true) {
              allowedTypes.push(deliverable);
            }
          });

          this.setState({
            deliverabletypes: allowedTypes
          })

          this.toggleLoadingPanel(false);

          if (callback && typeof callback === 'function') {
            callback();
          }
        }
      }).catch(err => {
        console.log(err);
        this.toggleLoadingPanel(false);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      })
  }

  /**
  * File has been successfully uploaded
  */
  completed = () => {
    this.props.completed();
    this.props.hidePopup();
  }

  /**
  * Set visibility of loading panel
  */
  toggleLoadingPanel = (status, message='Loading...') => {
    this.loadingcount = this.loadingcount + (status? 1 : -1);
    this.loadingcount = this.loadingcount < 0? 0: this.loadingcount;
    
    let newstatus = this.loadingcount > 0? true : false;

    this.setState({
      loadPanelVisible: newstatus,
      loadMessage: message
    });
  }

  /**
  * When an MPID is selected from the MPID dropdown in the upload popup
  * Select the MPID in the mpidDataSource and set the state based on the
  * MPIDs properties
  */
  handleMPIDChanged = (e) => {
    console.log("handleMPIDChanged")
    this.state.mpidSelectionDataSource.store().byKey(e.value).then(
      (mpid) => { 
        // set validation message for invalid mpids
        let validationMessage = this.createMPIDValidationMessage(mpid);

        this.MPID = mpid.MPID;
        this.vendor = mpid.ILIVendor;
        this.projectStatus = mpid.ProjectStatus;

        this.setState({
          assessmentPath: mpid.AssessmentPathName,
          subtype: mpid.SubType,
          assetOrLob: mpid.AssetOrLob,
          mpidValidationMessage: validationMessage
        })

        this.filterDeliverabletypes();
        this.filterMPIDs();
    },
      (error) => {
        this.MPID = ''
        this.vendor = '';
        this.projectStatus = '';

        this.setState({
          assessmentPath: '',
          subtype: '',
          assetOrLob: '',
          mpidValidationMessage: ''
        })

        this.filterDeliverabletypes();
        this.filterMPIDs();
      }
    );
  }

  /**
  * Dynamically create the invalid MPID message based on what properties
  * of the MPID are invalid
  */
  createMPIDValidationMessage = (mpid) => {
    let validationMessage = '';
    // status must be defined based on status list
    if (!validMPIDProjectStatusList.includes(mpid.ProjectStatus)) {
      validationMessage = 'MPIDs project status must be defined in Master Plan.  ';
    }
    // MPID must have assessment path
    if (!mpid.AssessmentPathName || mpid.AssessmentPathName.length === 0) {
      validationMessage += 'MPIDs assessment path must be set in Master Plan.  ';
    }
    // MPID must have vendor
    if (!mpid.ILIVendor || mpid.ILIVendor.length === 0) {
      validationMessage += 'MPIDs vendor must be set in ILI Tracker.  ';
    }

    return validationMessage;
  }

  /**
  * Check validity of MPID selection in the upload popup
  * If valid return true
  */
  checkMPID = (e) => {
    console.log("checkMPID")
    // boolean indicating validity of MPID
    let validMPID = true;
    // MPID must have defined project status
    if (!validMPIDProjectStatusList.includes(this.projectStatus)) {
      validMPID = false;
    }
    // MPID must have assessment path
    if (!this.state.assessmentPath || this.state.assessmentPath.length === 0) {
      validMPID = false;
    }
    // MPID must have vendor
    if (!this.vendor || this.vendor.length === 0) {
      validMPID = false;
    }

    return validMPID;
  }

  filterDeliverabletypes = () => {
    // arrays used to store all deliverable types that are parent types
    // and all deliverable parent types that are available after filtering based on mpid subtype (respectively)
    let allParentTypes = [];
    let filteredParentTypes = [];

    // clear the data source
    this.state.deliverableTypeDataSource.store().clear();
    this.state.deliverableSubtypeDataSource.store().clear();

    this.setState({
      deliverableType: {
        displayName: '',
        type: ''
      },
      deliverableSubtype: {
        displayName: '',
        type: ''
      },
      deliverableSubtypeVisible: false
    });

    this.state.deliverabletypes.forEach((deliverable) => {
      // deliverable type must be active to be added to the datasources
      // if the deliverable type is 'parent' type it is added to the deliverableTypeDataSource
      // if deliverable type is a subtype it is added to the deliverableSubtypeDataSource
      if (deliverable.Active) {
        if (deliverable.ParentType == null) {
          this.state.deliverableTypeDataSource.store().insert(deliverable);
        }
        else {
          this.state.deliverableSubtypeDataSource.store().insert(deliverable);
          // if parent type of deliverable subtype is not yet in allParentTypes array, add it
          if (!allParentTypes.includes(deliverable.ParentType)) {
            allParentTypes.push(deliverable.ParentType);
          }
        }
      }
    })

    // allow deliverable subtype if it has no Master Plan subtypes specified or if it does
    // specify Master Plan Subtypes and the selected MPID's subtype is included
    this.state.deliverableSubtypeDataSource.filter(((deliverableSub) => {
      if (deliverableSub.MasterPlanSubtypes.length === 0 || this.state.subtype === '' || deliverableSub.MasterPlanSubtypes.includes(this.state.subtype)) {
        // if it has not yet been added, add the parent type of the subtype deliverable to the filteredParentTypes array
        if (!filteredParentTypes.includes(deliverableSub.ParentType)) {
          filteredParentTypes.push(deliverableSub.ParentType);
        }
        return true;
      }
      else {
        return false;
      }
      }) 
    );

    // filter the available deliverable types and subtypes based on the MPID subtype
    // allow deliverable type if it is a filtered parent type or is not a parent type at all
    // and if it has no Master Plan subtypes specified or if it does
    // specify Master Plan Subtypes - the selected MPID's subtype is included
    this.state.deliverableTypeDataSource.filter(((deliverable) => {
      // 
      return ((filteredParentTypes.includes(deliverable.Type) || !allParentTypes.includes(deliverable.Type)) && (deliverable.MasterPlanSubtypes.length === 0 || this.state.subtype === '' || deliverable.MasterPlanSubtypes.includes(this.state.subtype)));
      }) 
    );
    // load the deliverable subtype data source before the deliverable types
    this.state.deliverableSubtypeDataSource.load().then(() => {
      // load the deliverable data sources to see new items
      this.state.deliverableTypeDataSource.load();
    });
  }

  /**
  * Filter options in the deliverable subtype drop down of the upload popup based
  * on the selection made in the deliverable type drop down (and the MPID subtype)
  */
  filterDeliverableSubtypes = (parentType) => {
    // allow deliverable subtype if it has no Master Plan subtypes specified or if it does
    // specify Master Plan Subtypes and the selected MPID's subtype is included.
    // The deliverable subtype must have it's parent type selected from the deliverable type drop down
    this.state.deliverableSubtypeDataSource.filter(((deliverableSub) => {
      return  ((deliverableSub.MasterPlanSubtypes.length === 0 || this.state.subtype === '' || deliverableSub.MasterPlanSubtypes.includes(this.state.subtype))  
          && deliverableSub.ParentType === parentType);
      }) 
    );
    this.state.deliverableSubtypeDataSource.load();
  }

  filterMPIDs = () => {
    this.setState({
      impactedMPIDs: []
    })

    this.state.mpidDropdownDataSource.filter([
      ["MPID", "<>", this.MPID]
    ]);
    this.state.mpidDropdownDataSource.load();

    // update dropdownbox input field
    let field = this.dropDownBoxRef.current.instance.field();
    field.value= this.state.impactedMPIDs;

    this.dropDownBoxRef.current.instance.focus();
  }

  /**
  * When selection is made from the deliverable type drop down in 
  * the upload popup set the deliverable type in the state, clear the deliverable subtype selection in state
  * and set visibility of the deliverable subtype in state (reflected in UI)
  */
  handleDeliverableTypeChanged = (e) => {
    if (e.value == null) {
      this.setState({
        deliverableType: {
          displayName: '',
          type: ''
        },
        deliverableSubtype: {
          displayName: '',
          type: ''
        },
        deliverableSubtypeVisible: (this.state.deliverableSubtypeDataSource.items().length > 0) ? true : false
      })
    }
    else {
      this.state.deliverableTypeDataSource.store().byKey(e.value)
      .then(
        (deliverable) => { 
          this.filterDeliverableSubtypes(deliverable.Type);
          this.setState({
            deliverableType: {
              displayName: deliverable.DisplayName,
              type: deliverable.Type 
            },
            deliverableSubtype: {
              displayName: '',
              type: ''
            },
            deliverableSubtypeVisible: (this.state.deliverableSubtypeDataSource.items().length > 0) ? true : false
          })
        }, (error) => {});
    }
    
  }

  /**
  * When selection is made from the deliverable subtype drop down in 
  * the upload popup set the deliverable subtype selection in state
  */
  handleDeliverableSubtypeChanged = (e) => {
    if (e.value == null) {
      this.setState({
        deliverableSubtype:{
          displayName: '',
          type: ''
        }
      })
    }
    else {
      this.state.deliverableSubtypeDataSource.store().byKey(e.value).then(
        (deliverableSub) => { 
          this.setState({
            deliverableSubtype:{
              displayName: deliverableSub.DisplayName,
              type: deliverableSub.Type
            }
          })
        }, (error) => {});
    }
  }

  handleRequestReasonChanged = (e) => {
    this.setState({
      requestReason: e.value,
      requestDetails: '',
      requestDetailsVisible: e.value === 'Other'? true : false
    })
  }

  /**
  * Check that deliverable subtype selection is a subtype of the deliverable type selected
  * in the upload popup
  */
  checkDeliverableSubtype = (e) => {
    let validSubtype = false;
    if (this.state.deliverableSubtypeVisible){
      console.log("Checking deliverable sub type")
      this.state.deliverableSubtypeDataSource.store().byKey(e.value).then(
        (deliverableSub) => { 
          console.log("Deliverable Parent Subtype: ", deliverableSub.ParentType);
        this.state.deliverableTypeDataSource.store().byKey(deliverableSub.ParentType).then(
          (deliverable) => { 
            console.log("Deliverable type: ", deliverable.Type);
          if (deliverable.Type === deliverableSub.ParentType && deliverable.DisplayName === this.state.deliverableType.displayName) {
            console.log("deliverable subtype parent matches deliverable type");
            validSubtype = true;
          }
          else {
            console.log("deliverable subtype and deliverable discovered but do not match");
          }
        },
          (error) => {
            console.log("Cannot find deliverable");
          }
        );
      },
        (error) => {
          console.log("Cannot find deliverable sub type");
        }
      );
    }
    else {
      console.log("subtype not visible so no actual validation: ", this.state.deliverableSubtype);
      validSubtype = true;
    }
    
    return validSubtype;
  }

  /**
  * Submit Due Date Extension Request
  */
  handleSubmit = (e) => {
    let result = this.validationGroup.instance.validate();
    // if invalid data then exit method
    if (!result.isValid) {
      return false;
    } 

    // API Gateway url pointing to upload lambda
    let url = `${process.env.REACT_APP_API_GATEWAY}/submitDueDateExtension`;
    // authentication info
    let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));

    // metadata tags for file
    let body = {
      "MPID": this.MPID,
      "AssessmentPathName": this.state.assessmentPath != null ? this.state.assessmentPath : "",
      "SubType": this.state.subtype != null ? this.state.subtype : "",
      "AssetOrLob": this.state.assetOrLob != null ? this.state.assetOrLob : "",
      "ILIReportType": this.state.deliverableType.type,
      "ILIReportSubType": this.state.deliverableSubtype.type,
      "RequstedDueDate": this.state.requestedDueDate? (new Date(this.state.requestedDueDate)).toISOString() : "",
      "RequestReason": this.state.requestReason,
      "RequestDetails": this.state.requestDetails,
      "ImpactedProjectDetails": this.state.impactedProjectDetails,
      "ImpactedMPIDs": this.state.impactedMPIDs,
      "ILIVendor": this.publisher,
      "CreatedBy": authInfo.claims.email
    }

    console.log("onsubmit body=", body);
    
    this.toggleLoadingPanel(true, 'Sending...');
    axios.post(url, body, {headers: getRequestHeaders()})
      .then((result) => {
        this.completed();
        alert({message:'<b style="color:rgb(92,184,92)";>Request sent successfully.</b>', showTitle:false});
      }).catch(error => {
          console.log(error);
          alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>An error occured while sending the request.</b>', showTitle:false});
      })
      .then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  onSelectionChanged = (e) => {
    this.setState({
      impactedMPIDs: (e.selectedRowKeys.length && e.selectedRowKeys) || []
    });

    // update dropdownbox input field
    let field = this.dropDownBoxRef.current.instance.field();
    field.value= this.state.impactedMPIDs;

    this.dropDownBoxRef.current.instance.focus();
  }

  onValueChanged =(e) => {
    this.setState({
      impactedMPIDs: []
    });
  }

  dataGridRender = () => {
    return (
      <DataGrid
        dataSource={this.state.mpidDropdownDataSource}
        scrolling={{ mode: 'standard', showScrollbar: 'always' }}
        selection={{ mode: 'multiple', allowSelectAll: false, showCheckBoxesMode: 'always' }}
        selectedRowKeys={this.state.impactedMPIDs}
        onSelectionChanged={this.onSelectionChanged}>
        <Paging enabled={true} pageSize={5} />
        <Pager
          visible={true}
          showPageSizeSelector={false}
          showNavigationButtons={true}
          showInfo={true} />
        <FilterRow visible={true} />
        <Column dataField="MPID" width={110} caption='MPID' allowHeaderFiltering={true} />
        <Column dataField="AssessmentPathName" caption='Assessment Path' allowHeaderFiltering={false} />
      </DataGrid>
    );
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name="loadPanel"
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true}
        />
        <Popup
          width={730}
          height={760}
          showTitle={true}
          title='Deliverable Extension Request'
          dragEnabled={false}
          hideOnOutsideClick={false}
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.2)"
          visible={this.props.popupVisible}
          onHiding={this.props.hidePopup}
          onShown={this.getPopupData}>
          <ValidationGroup ref={ref => this.validationGroup = ref}>
            <div id="popup" className="dde-popup-property-details">
              <div className="dx-fieldset">
                <div className="dx-field">
                  <div className="dx-field-label">MPID:</div>
                  <div className="dx-field-value">
                    <SelectBox
                      displayExpr="MPID"
                      valueExpr="MPID"
                      placeholder="Type in MPID"
                      dataSource={this.state.mpidSelectionDataSource}
                      onValueChanged={this.handleMPIDChanged}
                      searchEnabled={true}
                      searchMode={"contains"}
                      searchExpr={"MPID"}
                      searchTimeout={200}
                      minSearchLength={5}
                      showClearButton={true}
                      showDataBeforeSearch={false}>
                      <Validator>
                        <RequiredRule />
                        <CustomRule 
                          ignoreEmptyValue={true}
                          message={this.state.mpidValidationMessage}
                          validationCallback={(e) => this.checkMPID(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </SelectBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Assessment Path:</div>
                  <div className="dx-field-value">
                    <TextBox
                      name="AssessmentPathTxtBox"
                      value={this.state.assessmentPath}
                      readOnly={true} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Subtype:</div>
                  <div className="dx-field-value">
                    <TextBox
                      name="SubtypeTxtBox"
                      value={this.state.subtype}
                      readOnly={true} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Asset/LOB:</div>
                  <div className="dx-field-value">
                    <TextBox
                      name="AssetOrLobTxtBox"
                      value={this.state.assetOrLob}
                      readOnly={true} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Deliverable Type:</div>
                  <div className="dx-field-value">
                    <SelectBox
                      displayExpr="DisplayName"
                      valueExpr="Type"
                      dataSource={this.state.deliverableTypeDataSource}
                      value={this.state.deliverableType.type}
                      onValueChanged={this.handleDeliverableTypeChanged}
                      searchEnabled={true}
                      searchMode={"contains"}
                      searchExpr={"DisplayName"}
                      searchTimeout={200}
                      minSearchLength={0}
                      showClearButton={true}
                      showDataBeforeSearch={false} 
                      wrapItemText={true}>
                      <Validator>
                        <RequiredRule />
                      </Validator>
                    </SelectBox>
                  </div>
                </div>
                <div className="dx-field" style={{ display: (this.state.deliverableSubtypeVisible ? 'block' : 'none' )}}>
                  <div className="dx-field-label">Deliverable Subtype:</div>
                  <div className="dx-field-value">
                    <SelectBox
                      displayExpr="DisplayName"
                      valueExpr="Type"
                      dataSource={this.state.deliverableSubtypeDataSource}
                      value={this.state.deliverableSubtype.type}
                      onValueChanged={this.handleDeliverableSubtypeChanged}
                      searchEnabled={true}
                      searchMode={"contains"}
                      searchExpr={"DisplayName"}
                      searchTimeout={200}
                      minSearchLength={0}
                      showClearButton={true}
                      showDataBeforeSearch={false} >
                      <Validator>
                        <CustomRule 
                          ignoreEmptyValue={false}
                          message="Value selected must be a subtype of the deliverable type"
                          validationCallback={(e) => this.checkDeliverableSubtype(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </SelectBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{requstedDueDate_label()}</div>
                  <div className="dx-field-value">
                    <DateBox
                      type="datetime"
                      useMaskBehavior={true}
                      showClearButton={true}
                      displayFormat="yyyy-MM-dd HH:mm:ss"
                      onValueChanged={(e) => this.setState({requestedDueDate: e.value})} >
                      <Validator>
                        <RequiredRule />
                      </Validator>
                    </DateBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Reason for Timeline Change:</div>
                  <div className="dx-field-value">
                    <SelectBox 
                      items={requestReasonOptions} 
                      showClearButton={true}
                      onValueChanged={this.handleRequestReasonChanged} >
                      <Validator>
                        <RequiredRule />
                      </Validator>
                    </SelectBox>
                  </div>
                </div>
                <div className="dx-field" style={{ display: (this.state.requestDetailsVisible ? 'block' : 'none' )}}>
                  <div className="dde-popup-option">
                    <span>Additonal Timeline Change Details:</span>
                    <TextArea height={50} maxLength={4000} onValueChanged={(e) => this.setState({requestDetails: e.value})} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dde-popup-option">
                    <span>Are there any other projects impacted by this change? If yes, please provide project details:</span>
                    <br/>
                    <span><i>Note: If timelines of projects are impacted please submit a separate form.</i></span>
                    <TextArea height={50} maxLength={4000} onValueChanged={(e) => this.setState({impactedProjectDetails: e.value})} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dde-popup-option">
                    <span>If applicable, impacted Project's MPID:</span>
                    <DropDownBox
                      ref = {this.dropDownBoxRef}
                      displayExpr="MPID"
                      valueExpr="MPID"
                      value=""
                      deferRendering={false}
                      showClearButton={true}
                      contentRender={this.dataGridRender} 
                      onValueChanged={(e) => this.onValueChanged(e)}>
                    </DropDownBox>
                  </div>
                </div>
              </div>
            </div>
          </ValidationGroup>
          <div className="dde-popup-property-buttons">
            <Button
              icon="upload"
              text="Submit"
              width={210}
              height={44}
              elementAttr={{class: 'favorites'}}
              onClick={this.handleSubmit} />
          </div>
        </Popup>
      </React.Fragment>
    );
  }
}
