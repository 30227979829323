import React from 'react';
import axios from "axios";
import { LoadPanel } from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import TextArea from 'devextreme-react/text-area';
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import Popup from 'devextreme-react/popup';
import { alert } from 'devextreme/ui/dialog';
import Validator, { RequiredRule, CustomRule } from "devextreme-react/validator";
import ValidationGroup from 'devextreme-react/validation-group';
import './UpdateVendorRequest.css'
import { getRequestHeaders, getLoginVendor } from '../Utility/Utils.js'

const statusOptions = [
  'Submitted',
  'Backlog',
  'Cancelled',
  'Complete'
]

export default class UpdateVendorRequest extends React.Component {
  constructor(props){
    super(props);

		this.publisher = getLoginVendor();
    this.loadingcount = 0;
    
    this.state = {
      loadPanelVisible: false,
      loadMessage: 'Loading...',
      status: '',
      assignedTo: '',
      assignedToEmail: '',
      response: '',
      description: '',
      mpid: '',
      assignees: [],
    };

    this.statusSelectBoxRef = React.createRef();
    this.assigneeDropDownBoxRef = React.createRef();
  }
  
  /**
  * When the upload popup is opened in the UI - data is retrieved for drop downs in the popup
  */
  getPopupData = () => {
    this.getAssignee();

    this.setState({
      status: this.props.vendorRequest['Status'],
      assignedTo: this.props.vendorRequest['AssignedTo'],
      assignedToEmail: this.props.vendorRequest['AssignedToEmail'],
      mpid: this.props.vendorRequest['MPID'],
      description: this.props.vendorRequest['Description'],
      response: this.props.vendorRequest['Response']
    });

    let assignee = this.assigneeDropDownBoxRef.current.instance.field();
    assignee.value= this.state.assignedTo;

    let status = this.statusSelectBoxRef.current.instance.field();
    status.value= this.state.status;
  }

  getAssignee = () => {
    let url = `${process.env.REACT_APP_API_GATEWAY}/getVendorRequestAssignees?default=no`;
    console.log("url: ", url);
    this.toggleLoadingPanel(true);

    axios.get(url, {headers: getRequestHeaders()})
      .then(response => {
        console.log(response);
        const assignees = response.data.result
        if(assignees !== null && assignees !== ""){
          this.setState({
            assignees: assignees
          })
        }
      }).catch(err => {
        console.log(err);
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
      }).then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  /**
  * File has been successfully uploaded
  */
  completed = () => {
    this.props.completed();
    this.props.hidePopup();
  }

  /**
  * Set visibility of loading panel
  */
  toggleLoadingPanel = (status, message='Loading...') => {
    this.loadingcount = this.loadingcount + (status? 1 : -1);
    this.loadingcount = this.loadingcount < 0? 0: this.loadingcount;
    
    let newstatus = this.loadingcount > 0? true : false;

    this.setState({
      loadPanelVisible: newstatus,
      loadMessage: message
    });
  }

  /**
  * Submit Due Date Extension Request
  */
  handleSubmit = (e) => {
    let result = this.validationGroup.instance.validate();
    // if invalid data then exit method
    if (!result.isValid) {
      return false;
    } 

    // API Gateway url pointing to upload lambda
    let url = `${process.env.REACT_APP_API_GATEWAY}/saveVendorRequest`;
    // authentication info
    let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));

    // metadata tags for file
    let body = {
      "RequestID": this.props.vendorRequest['RequestID'],
      "Status": this.state.status,
      "Response": this.state.response,
      "AssignedTo": this.state.assignedTo,
      "AssignedToEmail": this.state.assignedToEmail,
      "UpdatedBy": authInfo.claims.name,
      "UpdatedByEmail": authInfo.claims.email
    }

    console.log("onsubmit body=", body);
    
    this.toggleLoadingPanel(true, 'Sending...');
    axios.post(url, body, {headers: getRequestHeaders()})
      .then((result) => {
        this.completed();
        alert({message:'<b style="color:rgb(92,184,92)";>Request sent successfully.</b>', showTitle:false});
      }).catch(error => {
          console.log(error);
          alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>An error occured while sending the request.</b>', showTitle:false});
      })
      .then(() => {
        this.toggleLoadingPanel(false);
      })
  }

  onStatusChanged = (e) => {
    if (this.props.vendorRequest['Status'] === 'Complete' && e.value !== 'Complete') {
      alert("Changing status will re-open the request.", "Warning");
    }

    this.setState({
      status: e.value
    });
    
    let status = this.statusSelectBoxRef.current.instance.field();
    status.value= this.state.status;
  }

  onAssigneeSelectionChanged = (e) => {
    this.setState({
      assignedTo: (e.selectedRowKeys.length && e.selectedRowKeys[0]['UserName']) || '',
      assignedToEmail: (e.selectedRowKeys.length && e.selectedRowKeys[0]['UserEmail']) || ''
    });

    let field = this.assigneeDropDownBoxRef.current.instance.field();
    field.value= this.state.assignedTo;

    this.assigneeDropDownBoxRef.current.instance.close();
  }

  onAssigneeChanged =(e) => {
    this.setState({
      assignedTo: '',
      assignedToEmail: ''
    });

    let assignee = this.assigneeDropDownBoxRef.current.instance.field();
    assignee.value= this.state.assignedTo;
  }

  checkAssignee = (e) => {
    if (this.state.assignedTo === '') 
      return false;
    else 
      return true;
  }

  checkResponse = (e) => {
    // if only assignee changed
    if (this.state.status === this.props.vendorRequest['Status'] && this.state.assignedTo !== this.props.vendorRequest['AssignedTo']) 
      return true;
    else if (!this.state.response || this.state.response === '')
      return false;
    else
      return true;
  }

  assigneeGridRender = () => {
    return (
      <DataGrid
        dataSource={this.state.assignees}
        height={400}
        selection={{ mode: 'single'}}
        showBorders={true}
        showRowLines={true}
        scrolling={{ mode: 'standard', showScrollbar: 'always' }}
        onSelectionChanged={this.onAssigneeSelectionChanged}>
        <FilterRow visible={true} />
        <Column dataField="UserName" width={200} caption='User' allowHeaderFiltering={false} defaultSortOrder="asc" />
        <Column dataField="UserEmail" caption='Email' allowHeaderFiltering={false} />
      </DataGrid>
    );
  }

  render() {
    return (
      <React.Fragment>
        <LoadPanel
          name="loadPanel"
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          visible={this.state.loadPanelVisible}
          message={this.state.loadMessage}
          showIndicator={true}
          showPane={true}
        />
        <Popup
          width={800}
          height={770}
          showTitle={true}
          title={(this.props.readOnly? '':'Update ') + 'Vendor Request - Request ID: ' + this.props.vendorRequest['RequestID']}
          dragEnabled={false}
          hideOnOutsideClick={false}
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.2)"
          visible={this.props.popupVisible}
          onHiding={this.props.hidePopup}
          onShown={this.getPopupData}>
          <ValidationGroup ref={ref => this.validationGroup = ref}>
            <div id="popup" className="vr-update-popup-property-details">
              <div className="dx-fieldset">
                <div className="dx-field">
                  <div className="vr-update-popup-option">
                    <span>Status:</span>
                    <SelectBox 
                      ref = {this.statusSelectBoxRef}
                      items={statusOptions} 
                      showClearButton={false}
                      value={this.state.status}
                      readOnly={this.props.readOnly}
                      onValueChanged={this.onStatusChanged} >
                      <Validator>
                        <RequiredRule />
                      </Validator>
                    </SelectBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="vr-update-popup-option">
                    <span>Assigned To:</span>
                    <DropDownBox
                      ref = {this.assigneeDropDownBoxRef}
                      displayExpr="UserName"
                      valueExpr="UserName"
                      value=""
                      deferRendering={false}
                      showClearButton={false}
                      readOnly={this.props.readOnly}
                      contentRender={this.assigneeGridRender} 
                      onValueChanged={(e) => this.onAssigneeChanged(e)}>
                      <Validator>
                        <CustomRule 
                          ignoreEmptyValue={false}
                          message="Required"
                          validationCallback={(e) => this.checkAssignee(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </DropDownBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="vr-update-popup-option">
                    <span>MPID(s):</span>
                    <TextArea 
                      height={40} 
                      value={this.state.mpid}
                      readOnly={true} >
                    </TextArea>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="vr-update-popup-option">
                    <span>Description:</span>
                    <TextArea 
                      height={170} 
                      value={this.state.description}
                      readOnly={true} >
                    </TextArea>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="vr-update-popup-option">
                    <span>Response:</span>
                    <TextArea 
                      height={170} 
                      maxLength={4000} 
                      value={this.state.response}
                      readOnly={this.props.readOnly}
                      onValueChanged={(e) => this.setState({response: e.value})} >
                      <Validator>
                        <CustomRule 
                          ignoreEmptyValue={false}
                          message="Required"
                          validationCallback={(e) => this.checkResponse(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </TextArea>
                  </div>
                </div>
              </div>
            </div>
          </ValidationGroup>
          <div className="vr-update-popup-property-buttons">
            <Button
              icon="upload"
              text="Submit"
              width={210}
              height={44}
              elementAttr={{class: 'favorites'}}
              visible={!this.props.readOnly}
              onClick={this.handleSubmit} />
          </div>
        </Popup>
      </React.Fragment>
    );
  }
}
