import React from 'react'
import axios from "axios";
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Popup from 'devextreme-react/popup';
import Validator, { RequiredRule, CustomRule } from "devextreme-react/validator";
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Box, { Item } from 'devextreme-react/box';
import { alert } from 'devextreme/ui/dialog';
import ValidationGroup from 'devextreme-react/validation-group';
import LocalStore from 'devextreme/data/local_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Editing, Paging, Pager } from 'devextreme-react/data-grid';
import { companies } from '../Data/companies.js';
import { getRequestHeaders, getLoginVendor } from '../Utility/Utils.js'
import { validMPIDProjectStatusList } from '../Data/validMPIDProjectStatusList.js';
import AddFilePopup from './AddFile.js';

import './AddDeliverable.css'

const addButtonOptions = {
  icon: 'plus',
  hint: 'Upload a file'
};

const FILE_CHUNK_SIZE = 200*1024*1024 // 200 MB

export default class AddDeliverable extends React.Component{
    constructor(props){
      super(props);

      this.state = {
        publisher: getLoginVendor(),
        loadPanelVisible: false,
        loadMessage: 'Loading...',
        totalPackage: 0,
        completedPackage: 0,
        addFilePopupVisible: false,
        destination: '',
        MPID: '',
        vendor: '',
        country: '',
        assetOrLob: '',
        assessmentPath: '',
        subtype: '',
        projectStatus: '',
        mpidValidationMessage: '',
        mpidDataSource: new DataSource({
          store: new LocalStore({
            key: 'MPID',
            data: [],
            name: 'mpidData'
          })
        }),
        destinationDataSource: new DataSource({
          store: new LocalStore({
            key: 'key',
            data: [],
            name: 'destinationData'
          })
        }),
        filesDataSource: []
      };
    }

    componentWillUnmount() {
      this.state.mpidDataSource.store().clear();
      this.state.destinationDataSource.store().clear();
    }

    /**
    * Get all folder destinations from data/companies.js 
    * and map them to the destinationDataSource
    */
    getDestinations = () => {
      companies.forEach((company) => {
        if (this.state.publisher === 'TC') {
          if (company.Id !== 'All') {
            this.state.destinationDataSource.store().insert(company);
          }
        } else {
          if (this.state.publisher === company.Id) {
            this.state.destinationDataSource.store().insert(company);
          }
        }
      });
    }

    /**
    * When the upload popup is opened in the UI - data is retrieved for drop downs in the popup
    */
    getPopupData = () => {
      this.getMPIDs();
      this.getDestinations();
    }

    /**
    * Get all available MPIDs using API Gateway and Lambda 
    * and map them to the mpidDataSource
    */
    getMPIDs = () => {
      // API Gateway url to the get-mpid lambda
      let mpids_url = `${process.env.REACT_APP_API_GATEWAY}/getMPIDs?vendor=${this.state.publisher}`;
      console.log("url: ", mpids_url);

      this.toggleLoadingPanel(true);
      
      // clear the data source
      this.state.mpidDataSource.store().clear();
      
      // API call to retrieve deliverable types
      axios.get(mpids_url, {headers: getRequestHeaders()})
        .then(response => {
          console.log('response:', response);
          // MPIDs
          const mpids = response.data.MPIDs
          if(mpids !== null && mpids !== ""){
              // map MPID to mpidDataSource
              mpids.forEach((mpid) => {
                this.state.mpidDataSource.store().insert(mpid);
              })
          }
          this.toggleLoadingPanel(false);
        }).catch(err => {
          console.log(err);
          this.toggleLoadingPanel(false);
          alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Load data failed! Session might be expired. Reload the page and try again or contact support.</b>', showTitle:false});
        })
    }

    /**
    * Reset state when the upload popup is hidden
    */
    handlePopupHidden = (e) => {
      this.setState({
        totalPackage: 0,
        completedPackage: 0,
        destination: '',
        MPID: '',
        vendor: '',
        country: '',
        assetOrLob: '',
        assessmentPath: '',
        subtype: '',
        projectStatus: '',
        mpidValidationMessage: '',
        filesDataSource: []
      });
      // call parent componenet to hide the popup
      this.props.hidePopup();
    }

    /**
    * File has been successfully uploaded
    */
   completed = () => {
      this.props.completed(this.state.destination);
      this.handlePopupHidden();
    }

    /**
    * Set visibility of loading panel
    */
    toggleLoadingPanel = (status, message='Loading...') => {
      this.setState({
        loadPanelVisible: status,
        loadMessage: message
      });
    }

    /**
    * When an MPID is selected from the MPID dropdown in the upload popup
    * Select the MPID in the mpidDataSource and set the state based on the
    * MPIDs properties
    */
    handleMPIDChanged = (e) => {
      this.state.mpidDataSource.store().byKey(e.value).then(
        (mpid) => { 
          // set validation message for invalid mpids
          let validationMessage = this.createMPIDValidationMessage(mpid);
          this.setState({
            MPID: mpid.MPID,
            country: mpid.Country,
            assetOrLob: mpid.AssetOrLob,
            assessmentPath: mpid.AssessmentPathName,
            subtype: mpid.SubType,
            projectStatus: mpid.ProjectStatus,
            vendor: mpid.ILIVendor,
            mpidValidationMessage: validationMessage
          })
      },
        (error) => {
          this.setState({
            MPID: '',
            country: '',
            assetOrLob: '',
            assessmentPath: '',
            subtype: '',
            projectStatus: '',
            vendor: '',
            mpidValidationMessage: ''
          })
        }
      );
      // (for internal users only)
      // only allow selection of TC internal or vendor of MPID in the destination
      // drop down of the upload popup
      this.filterDestinations();
    }

    /**
    * Dynamically create the invalid MPID message based on what properties
    * of the MPID are invalid
    */
    createMPIDValidationMessage = (mpid) => {
      let validationMessage = '';
      // status must be defined based on status list
      if (!validMPIDProjectStatusList.includes(mpid.ProjectStatus)) {
        validationMessage = 'MPIDs project status must be defined in Master Plan.  ';
      }
      // MPID must have assessment path
      if (!mpid.AssessmentPathName || mpid.AssessmentPathName.length === 0) {
        validationMessage += 'MPIDs assessment path must be set in Master Plan.  ';
      }
      // MPID must have vendor
      if (!mpid.ILIVendor || mpid.ILIVendor.length === 0) {
        validationMessage += 'MPIDs vendor must be set in ILI Tracker.  ';
      }

     return validationMessage;
    }

    /**
    * Dynamically filter the destination options in the destinations
    * drop down of the upload popup based on the vendor of the MPID selected
    * (internal users only)
    */
    filterDestinations = () => {
      // Fitler the data source to only show TC Internal and vendor of the MPID
      this.state.destinationDataSource.filter([
        ["Id", "=", this.state.vendor],
        "or",
        ["Id", "=", "TC"]
      ]);
      // load filter
      this.state.destinationDataSource.load();
    }

    /**
    * When selection is made from the destination type drop down in 
    * the upload popup set the destination in the state
    */
    handleDestinationChanged = (e) => {
      this.setState({
        destination: e.value
      });
    }

    /**
    * Check validity of MPID selection in the upload popup
    * If valid return true
    */
    checkMPID = (e) => {
      // boolean indicating validity of MPID
      let validMPID = true;
      // MPID must have defined project status
      if (!validMPIDProjectStatusList.includes(this.state.projectStatus)) {
        validMPID = false;
      }
      // MPID must have assessment path
      if (!this.state.assessmentPath || this.state.assessmentPath.length === 0) {
        validMPID = false;
      }
      // MPID must have vendor
      if (!this.state.vendor || this.state.vendor.length === 0) {
        validMPID = false;
      }

     return validMPID;
    }

    /**
    * Check that destination selection is either TC Internal of the vendor assigned
    * to the MPID
    */
    checkDestination = (e) => {
      let tcInternal = 'TC';
      // console.log("Destination in state: " + this.state.destination);
      // tc internal can always be selected as destinatino (for internal users)
      if (this.state.destination === tcInternal) {
        // console.log("TC internal selected as destination or mpid does not have a vendor assigned");
        return true;
      }
      // if tc internal is not selected for destination and the mpid ILIVendor is not null
      else if (this.state.vendor !== null && this.state.vendor !== "") {
        // destination selected matches mpid ILIVendor
        if (this.state.destination.toUpperCase() === this.state.vendor.toUpperCase()) {
          return true;
        }
        // destination selected does not match mpid ILIVendor
        else {
          return false;
        }
      }
      // mpid does not have an ILIVendor and TC Internal was not selected
      else {
        // console.log("this state vendor is null");
      return false;
      }
    }

    /**
    * Check that there is at least one file selected for upload
    */
    checkFilesPresent = () => {
      if (!this.state.filesDataSource.length > 0) {
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>Must have at least one file for upload.</b>', showTitle:false});
        return false;
      }
      else {
        return true;
      }
    }

    /**
    * Check that the deliverable types associated with the files to be upload include the selected
    * MPID's subtype in the deliverable type's MasterPlanSubtypes array
    */
    checkFilesValidMPSubtypes = () => {
      let validSubtypes = this.state.filesDataSource.every((file) => {
        // return true if file deliverable type/subtype does not have any master plan subtypes specified
        // or if it does specify them that the mpid's subtype is included. If no mpid has been selected skip this validation as
        // the mpid validation will catch this error
        return (file.masterPlanSubtypes.length === 0 || file.masterPlanSubtypes.includes(this.state.subtype) || this.state.MPID === '');
      });
      if (!validSubtypes) {
        let invalidFilesHTMLTable = `
        <head>
          <style>
            .divScroll {
            overflow-y:scroll;
            height:110px;
            width:500px;
            }
            .invalidTable, .invalidTh, .invalidTr {
              border: 1px solid black;
              border-collapse: collapse;
            }
            .invalidTh, .invalidTd {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
              width: 240px;
            }
            .invalidTh {
              padding-left: 15px;
              padding-right: 15px;
            }
            .invalidTd { 
              border-right: 1px solid black;
              padding: 15px;
            }
            .invalidTd:hover {
              overflow: show;
              white-space: normal;
            }
          </style>
        </head>
        <body>
          <table class='invalidTable'><thead><th class='invalidTh'>File</th><th class='invalidTh'> Valid Master Plan Subtypes</th><thead></table>
        <div class="divScroll">
          <table class='invalidTable'><tbody>`;

        this.state.filesDataSource.forEach((file) => {
          if (file.masterPlanSubtypes.length > 0 && !(file.masterPlanSubtypes.includes(this.state.subtype))) {
            invalidFilesHTMLTable += `<tr class='invalidTr'><td class='invalidTd'>${file.fileName}</td><td class='invalidTd'>${file.masterPlanSubtypes.join(', ')}</td></tr>`;
          }
        })
        invalidFilesHTMLTable += `</tbody></table></div></body>`
        alert({message:`<b style="color:rgb(217,83,79)";>Invalid files do not match MPID sub type (${this.state.subtype}):</b></br></br>${invalidFilesHTMLTable}</br></br></br>Please remove these files or select a new MPID with a valid subtype.</br></br>`, showTitle:false});
        return validSubtypes;

      }
      else {
        return validSubtypes;
      }
    }

      /**
      * Upload file to S3 using API Gateway and Lambda
      */
    handleFileUpload = (e) => {
      // make sure all data associated with file upload is valid
      // (based on selections in upload popup)
      let result = this.uploadValidationGroup.instance.validate();
      //e.validationGroup.validate();
      let filePresent = this.checkFilesPresent();
      let validFileMPSubtypes = this.checkFilesValidMPSubtypes();
      // if invalid data then exit method
      if (!result.isValid || !filePresent || !validFileMPSubtypes) {
        return false;
      } 

      // selected folder for the file to be uploaded to in S3
      let folder = this.state.destination;
      // API Gateway url pointing to upload lambda
      let upload_url = `${process.env.REACT_APP_API_GATEWAY}/uploadFile`;
      // API Gateway url pointing to complete upload lambda
      let complete_upload_url =  `${process.env.REACT_APP_API_GATEWAY}/completeUpload`;
      // authentication info
      let authInfo = JSON.parse(sessionStorage.getItem('authInfo'));
      let userEmail = authInfo.claims.email;

      // number of files to be uploaded
      const numFiles = this.state.filesDataSource.length;
      console.log("numFiles=", numFiles);
      
      // parameter of 0 in this call is to start the index of files at 0
      // this method is recursively called within itself after a successful file upload
      this.uploadFile(userEmail, folder, upload_url, complete_upload_url, 1, numFiles);
    }
  
    /**
    * Upload file to S3 using API Gateway and Lambda
    */
    uploadFile = (userEmail, folder, upload_url, complete_upload_url, fileIndex, numFiles) => {
      let file = this.state.filesDataSource[0];
      console.log("FILE: ", file, " INDEX: ", fileIndex);
      
      // remove non ascii characters
      let fileName = file.fileName.replace(/[^\x00-\x7F]/g, "");
      let fileSize = file.size;
      let part_size = Math.ceil(fileSize / FILE_CHUNK_SIZE)
      console.log("Total file size: ", fileSize)

      // reset progress counter
      this.setState({
        totalPackage: part_size,
        completedPackage: 0
      });

      // metadata tags for file
      let body = {
        "file_name":fileName, 
        "part_size": part_size, 
        "size": fileSize,
        "folder": folder,
        "mpid": this.state.MPID,
        "country": this.state.country != null ? this.state.country : "",
        "asset_or_lob": this.state.assetOrLob != null ? this.state.assetOrLob : "",
        "assessment_path": this.state.assessmentPath != null ? this.state.assessmentPath : "",
        "subtype": this.state.subtype != null ? this.state.subtype : "",
        "deliverable_type": file.deliverableType.type,
        "deliverable_type_displayname": file.deliverableType.displayName,
        "deliverable_subtype": file.deliverableSubtype.type,
        "deliverable_subtype_displayname": file.deliverableSubtype.displayName,
        "ilireceivedate" : file.ilireceivedate,
        "vendor": this.state.vendor != null ? this.state.vendor : "",
        "publisher": this.state.publisher,
        "created_by": userEmail
      }
   
      console.log("uploadFile body: ", body);

      this.toggleLoadingPanel(true, `Uploading file ${fileIndex} of ${numFiles}...0%`);
      // API Gatway request
      axios.post(upload_url, body, {headers: getRequestHeaders()})
        .then(response => {
          // error in request
          if (response.data.error) {
            console.log(`Error happened: ${response.data.error}`);
            this.toggleLoadingPanel(false);
            alert({message:`<b style="color:rgb(217,83,79)";>${fileIndex - 1} file(s) were successfully uploaded. </br> An error occured while uploading the ${numFiles - fileIndex + 1} remaining file(s) </br> which can be viewed in the file table.</b> </br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>${response.data.error}</i>`, showTitle:false});
            return;
          }

          this.setState({
            loadMessage: `Uploading file ${fileIndex} of ${numFiles}...1%`
          });

          //successfull getting signed url. Add the item to the states with uploading status
          var t0 = performance.now();
          let urls = response.data.upload_urls;
          let upload_id = response.data.upload_id;
          console.log("urls: ", urls);

          delete axios.defaults.headers.put['Content-Type']
          const promises = []

          urls.forEach((url, part) =>{
            const currentPackage = part;
            const start = part * (FILE_CHUNK_SIZE)
            const end = (part + 1) * (FILE_CHUNK_SIZE)
            const blob = part <= part_size - 1? file.fileObj.slice(start, end) : file.fileObj.slice(start)

            const options = {
              method: "PUT",
                url: url,
                data: blob,
                headers: { "Content-Type": "multipart/form-data"}
            }

            promises.push(
              new Promise(async (resolve, reject) => {
                let retries = 0;
                const maxRetries = 3;
              
                while (retries < maxRetries) {
                  try {
                    const response = await axios(options);
                    this.setState({
                      completedPackage: this.state.completedPackage + 1,
                      loadMessage: `Uploading file ${fileIndex} of ${numFiles}...${ Math.round((this.state.completedPackage*100/this.state.totalPackage)*98)/100 + 1 }%`
                    });
                    console.log("currentPackage: ", currentPackage);
                    resolve(response);
                    break;
                  } catch (err) {
                    retries++;
                    if (retries === maxRetries) {
                      reject(err);
                    } else {
                      const status = err?.response?.status || 500;
                      console.log(`Error Status: ${status}`);
                      console.log(`Retry pacakge: ${currentPackage}`);
                    }
                  }
                }
              })   
            )
          })

          Promise.all(promises)
            .then((result) => {
              let etags = result.map((r, index)=> JSON.parse(`{"ETag": ${r.headers.etag}, "PartNumber": ${index+1}}`))
              body["parts"] = etags
              body["upload_id"] = upload_id

              axios.post(complete_upload_url, body, {headers : getRequestHeaders()})
                .then((result) => {
                  var t1 = performance.now()
                  console.log("Time taken to upload file: ", (t1 - t0), " milliseconds.")

                  this.setState({
                    loadMessage: `Uploading file ${fileIndex} of ${numFiles}...100%`
                  });
                  
                  // increment file index
                  fileIndex = fileIndex + 1;
                  // remove the file that was just uplaoded from the data source for the add files grid view
                  let files = this.state.filesDataSource.filter(function(file) {
                    return file.fileName !== fileName;
                  });
                  // update file store
                  this.setState({
                    filesDataSource: files
                  })

                  // call this.uploadFiles() until all files have been uploaded
                  if (this.state.filesDataSource.length > 0) {
                    this.uploadFile(userEmail, folder, upload_url, complete_upload_url, fileIndex, numFiles);
                  }
                  // all files have been uploaded
                  else {
                    this.toggleLoadingPanel(false);
                    // upload of files has completed
                    this.completed();
                    alert({message:`<b style="color:rgb(92,184,92)";>${numFiles} of ${numFiles} files uploaded successfully</b>`, showTitle:false});
                  }
                }).catch(error => {
                  console.log(`Error happened in completing multipart uploading: ${error}`);
                  this.toggleLoadingPanel(false);
                  alert({message:`<b style="color:rgb(217,83,79)";>${fileIndex - 1} file(s) were successfully uploaded. </br> An error occured while uploading the ${numFiles - fileIndex + 1} remaining file(s) </br> which can be viewed in the file table. </b></br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>Error happened in completing multipart uploading.</br>${error}</i>`, showTitle:false});
                })
            }).catch(error => {
              console.log(`Error happened in multipart uploading: ${error}`);
              this.toggleLoadingPanel(false);
              alert({message:`<b style="color:rgb(217,83,79)";>${fileIndex - 1} file(s) were successfully uploaded. </br> An error occured while uploading the ${numFiles - fileIndex + 1} remaining file(s) </br> which can be viewed in the file table. </b></br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>Error happened in multipart uploading.</br>${error}</i>`, showTitle:false});
            })
          }).catch(error => {
            console.log("Error happened in getting signed URL:", error);
            this.toggleLoadingPanel(false);
            alert({message:`<b style="color:rgb(217,83,79)";>${fileIndex - 1} file(s) were successfully uploaded. </br> An error occured while uploading the ${numFiles - fileIndex + 1} remaining file(s) </br> which can be viewed in the file table. </b></br></br> Please retry the upload.</br></br> <b>Error Details:</b></br><i>Error happened in getting signed URL.</br>${error}</i>`, showTitle:false});
          })
    }

    /**
    * Hide the Add File popup
    */
    hideAddFilePopup = () => {
      this.setState({
        addFilePopupVisible: false
      });
    }

    /**
    * Show the Add File popup
    */
    showAddFilePopup = () => {
      let result = this.uploadValidationGroup.instance.validate();
      if (!result.isValid) {
        alert({message:'<b style="color:rgb(217,83,79);font-size:16px";>You must select a destination.</b>', showTitle:false});
      }
      else {
        this.setState({
          addFilePopupVisible: true
        });
      }
    }

    /**
    * When Add File is clicked to submit the addition 
    * of a new file form the add file popup
    */
    addFile = (fileObj) => {
      let files = this.state.filesDataSource;
      files.push(fileObj);

      this.setState({
        filesDataSource: files
      })

      console.log('addFile store=', this.state.filesDataSource)
      this.hideAddFilePopup();
    }

  /**
   * Check file being added from the add file popup has not already been 
   * uploaded to S3
     */
    checkExistingS3Files = (fileName) => {
      let key = this.state.destination + "/" + fileName;
      let params = `key=${key}`;
      let url = `${process.env.REACT_APP_API_GATEWAY}/getFiles?${params}`;
      console.log("url: ", url);

      return (async () => {
        let valid = false;
        await axios.get(url, {headers: getRequestHeaders()})
          .then((response) => {
            console.log("response: ", response);
            valid = response.data.totalCount > 0 ? false : true;
          }).catch(err => {
            console.log("error: ", err);
          });

        return valid;
      })()
      
    }

  /**
    * Check file being added from the add file popup has not already been added 
    * (based on the file name) and is not currently in the Add File Grid of the Upload 
    * popup
    */
    checkExistingFileNamesToAdd = (fileName) => {
      // returns true if all files pass the validation and returns false if any fail the validation
      return this.state.filesDataSource.every((existingFile) => {
        return fileName !== existingFile.fileName;
      });
    }

    /**
    * Check file deliverable type/subtype being added from the add file popup 
    * to make sure a file with the same deliverable type/subtype has not already been added
    * (based on the file deliverable type/subtype) in the Add File Grid of the Upload 
    * popup
    */
   checkExistingFileDeliverableTypesToAdd = (deliverableType, deliverableSubtype) => {
      // returns true if all files pass the validation and returns false if any fail the validation
      return this.state.filesDataSource.every((existingFile) => {
        // there is no deliverable subtype so deliverable types are compared
        if (existingFile.deliverableSubtype.type === '') {
          return deliverableType.type !== existingFile.deliverableType.type;
        }
        // there is a deliverable subtype so deliverable subtypes are compared
        else {
          return deliverableSubtype.type !== existingFile.deliverableSubtype.type;
        }
      });
    }

    render() {
      // use this to re-render popup everytime the add file button is selected
      let addFilePopup = '';
      if (this.state.addFilePopupVisible){
        addFilePopup = <AddFilePopup 
          addFilePopupVisible={this.state.addFilePopupVisible} 
          hideAddFilePopup={this.hideAddFilePopup} 
          addFile={this.addFile} 
          checkExistingS3Files={this.checkExistingS3Files}
          checkExistingFileNamesToAdd={this.checkExistingFileNamesToAdd}
          checkExistingFileDeliverableTypesToAdd={this.checkExistingFileDeliverableTypesToAdd}
          mpSubtype={this.state.subtype}
        />
      }
      else {
        addFilePopup = '';
      }
      
      return (
        <React.Fragment>
          {addFilePopup}
          <LoadPanel
            name="loadPanel"
            shadingColor="rgba(0,0,0,0.4)"
            visible={this.state.loadPanelVisible}
            message={this.state.loadMessage}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
          <Popup
            width={750}
            height={800}
            showTitle={true}
            title='New Deliverable(s)'
            dragEnabled={false}
            hideOnOutsideClick={false}
            shading={true}
            shadingColor="rgba(0, 0, 0, 0.2)"
            visible={this.props.popupVisible}
            onHiding={(e) => this.handlePopupHidden(e)}
            onShown={this.getPopupData}>
            <ValidationGroup ref={ref => this.uploadValidationGroup = ref}>
              <div id="popup" className="d-popup-details">
                <div className="d-popup-options">
                  <div className="d-popup-option">
                    <span>MPID:</span>
                    <SelectBox
                      displayExpr="MPID"
                      valueExpr="MPID"
                      placeholder="Type in MPID"
                      dataSource={this.state.mpidDataSource}
                      onValueChanged={(e) => this.handleMPIDChanged(e)}
                      searchEnabled={true}
                      searchMode={"contains"}
                      searchExpr={"MPID"}
                      searchTimeout={200}
                      minSearchLength={5}
                      showDataBeforeSearch={false} 
                      >
                      <Validator>
                        <RequiredRule />
                        <CustomRule 
                        ignoreEmptyValue={true}
                        message={this.state.mpidValidationMessage}
                        validationCallback={(e) => this.checkMPID(e)}
                        reevaluate={true}
                        />
                      </Validator>
                    </SelectBox>
                    <div className="d-popup-option">
                      <Box
                        direction="row">
                          <Item ratio={0.3}></Item>
                        <Item ratio={1}>
                          <div className="d-popup-option">
                            <span>Country:</span>
                            <TextBox
                              name="CountryTxtBox"
                              readOnly={true} 
                              value={this.state.country}
                              width={200}
                              >
                            </TextBox>
                          </div>
                        </Item>
                        <Item ratio={1}>
                          <div className="d-popup-option">
                            <span>Asset / LOB:</span>
                            <TextBox
                              name="AssetOrLobTxtBox"
                              width={200}
                              value={this.state.assetOrLob}
                              readOnly={true} 
                              >
                            </TextBox>
                        </div>
                        </Item>
                      </Box>
                    </div>
                    <div className="d-popup-option">
                      <span>Assessment Path:</span>
                      <TextBox
                        name="AssessmentPathTxtBox"
                        value={this.state.assessmentPath}
                        readOnly={true} 
                        >
                      </TextBox>
                    </div>
                    <div className="d-popup-option">
                      <span>Subtype:</span>
                      <TextBox
                        name="SubtypeTxtBox"
                        value={this.state.subtype}
                        readOnly={true}
                        >
                      </TextBox>
                    </div>
                  </div>
                  <div className="d-popup-option">
                    <span>Destination:</span>
                    <SelectBox
                      displayExpr="Text"
                      valueExpr="Id"
                      dataSource={this.state.destinationDataSource}
                      onValueChanged={(e) => this.handleDestinationChanged(e)} 
                      searchEnabled={true}
                      searchMode={"contains"}
                      searchExpr={"text"}
                      searchTimeout={200}
                      minSearchLength={0}
                      showDataBeforeSearch={false}>
                      <Validator>
                        <RequiredRule />
                        <CustomRule 
                          ignoreEmptyValue={true}
                          message="Destination must be TC or the ILI vendor assigned to the MPID"
                          validationCallback={(e) => this.checkDestination(e)}
                          reevaluate={true}
                        />
                      </Validator>
                    </SelectBox>
                  </div>
                </div>
                < br/>
                <Toolbar>
                  <ToolbarItem location="after"
                    widget="dxButton"
                    onClick={this.showAddFilePopup} 
                    options={addButtonOptions} />
                </Toolbar>
              </div>
              <br/>
            </ValidationGroup>
            <DataGrid
              keyExpr="fileName"
              dataSource={this.state.filesDataSource}
              showBorders={true}
              showRowLines={true}
              rowAlternationEnabled={true}
              StateStoring={false}
              scrolling={{ mode: "standard",  showScrollbar: "always"}}
              height={250}>   
              <Paging defaultPageSize={100} />
              <Pager
                visible={true}
                showPageSizeSelector={false}
                showNavigationButtons={false}
                showInfo={true} />
              <Editing mode="row" useIcons={true} allowDeleting={true} />
              <Column dataField="fileName" caption='File Name' />
              <Column dataField="deliverableType.displayName" width={160} caption='Deliverable Type' />
              <Column dataField="deliverableSubtype.displayName" width={160} caption='Deliverable Subtype' /> 
            </DataGrid>         
            <div className="d-popup-buttons">
              <Button
                icon="plus"
                text="Upload"
                width={210}
                height={44}
                elementAttr={{class: 'favorites'}}
                onClick={this.handleFileUpload} />
            </div>
          </Popup>
        </React.Fragment>
      );
    }
}