import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import './VendorRequestTab.css';
import SubmitVendorRequest from '../Popups/SubmitVendorRequest.js';
import VendorRequestView from '../Views/VendorRequestView';
import { isReadOnly, isTCUser, getLoginFolder } from '../Utility/Utils.js'
import { companies } from '../Data/companies.js';

function renderCompanyLabel() {
  return <div><b>Vendor:</b></div>;
}

// options for toolbar buttons at top right of fileview grid
const refreshButtonOptions = {
  icon: 'refresh',
  hint: 'Refresh grid view'
};

const addButtonOptions = {
  icon: 'plus',
  hint: 'New Request'
};

const exportExcelButtonOptions = {
  icon: 'exportxlsx',
  hint: 'Export grid view to excel'
}

const resetLayoutButtonOptions = {
  icon: 'revert',
  hint: 'Reset grid view layout and filter'
};

export default class DueDateTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupVisible: false,
      currentFolder: getLoginFolder()
    };

    this.requestview = React.createRef();
    this.vendorsdropdown = React.createRef();
  
    this.selectBoxOptions = {
      width: 200,
      items: isTCUser()? companies : [],
      valueExpr: 'Id',
      displayExpr: 'Text',
      value: this.state.currentFolder,
      onValueChanged: (e) => this.handleFolderValueChanged(e)
    };
  }

  /**
    * Company filter in top left of UI is changed
    * Grid will update to reflect files in the company that was selected folder
    */
  handleFolderValueChanged = (e) => {
    console.log("selected folder: " + e.value);
    this.setState({
      currentFolder: e.value
    });
    this.refresh();
  }

  /**
    * Hide upload popup
    */
  hidePopup = () => {
    this.setState({
      popupVisible: false
    });
  }

  /**
    * Show upload popup
    */
  showPopup = () => {
    this.setState({
      popupVisible: true
    });
  }
  
  /**
    * Call the file grid view to export rows to excel
    */
  exportExcel = () => {
    this.requestview.current.onExporting();
  }

  /**
    * Refresh data grid with files
    */
  refresh = () => {
    this.requestview.current.refresh(this.state.currentFolder);
  }

  /**
  * Reset data grid layout
  */
  resetLayout = () => {
    this.requestview.current.resetLayout();
  }

  /**
  * Refresh file view after a successful file upload
  */
  completed = () => {
    this.refresh();
  }

  render() {
    let popup = '';
     if (this.state.popupVisible){
      popup = <SubmitVendorRequest 
        popupVisible={this.state.popupVisible} 
        hidePopup={this.hidePopup} 
        completed={this.completed} 
      />
    }
    else {
      popup = '';
    }

    return (
      <React.Fragment>
        {popup}
        <Toolbar>
          <Item location="left"
            locateInMenu="never"
            visible={isTCUser()}
            render={renderCompanyLabel} />
          <Item location="left"
            locateInMenu="never"
            visible={isTCUser()}
            widget="dxSelectBox"
            ref={this.vendorsdropdown}
            options={this.selectBoxOptions} />
          <Item location="after"
            widget="dxButton"
            onClick={this.exportExcel} 
            options={exportExcelButtonOptions} />
          <Item location="after"
            widget="dxButton"
            onClick={this.refresh} 
            options={refreshButtonOptions} />
          <Item location="after"
            widget="dxButton"
            onClick={this.resetLayout} 
            options={resetLayoutButtonOptions} />
          <Item location="after"
            widget="dxButton"
            visible={!isReadOnly()}
            onClick={this.showPopup} 
            options={addButtonOptions} />
        </Toolbar>
        <br/>
        <VendorRequestView ref={this.requestview} currentFolder={this.state.currentFolder}/>
      </React.Fragment>
    );
  }
}

